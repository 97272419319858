import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from "../../../../../components/Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { MedicamentosPacienteService } from '../../../../../services/EmergenciasPacienteService';
import api from '../../../../../services/api';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: "auto",
        width: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};

const ModalAlergias = (props, ref) => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const editAlergiasPaciente = (alergia, dados) => {
        let alergiasLocal = [...props.alergias];
        const index = alergiasLocal.indexOf(alergia);
        alergiasLocal.splice(index, 1, dados);
        props.setAlergias(alergiasLocal);
    }

    async function handleSubmit(data) {
        try {
            if (data.alergia === '') {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning')
            } else {
                const dados = {
                    alergia: data.alergia
                };
                if (props.modalData === null) {
                    let alergiasLocal = [...props.alergias];
                    if (props.paciente_id !== undefined && props.paciente_id !== null) {
                        dados.paciente_id = props.paciente_id;
                        await api.post('api/paciente-alergia', dados).then(({data}) => {
                            snackbar.displayMessage('Alergia adicionada com sucesso!', 'success');
                            props.callBack();
                            alergiasLocal.push(data);
                        }).catch((e) => {
                            snackbar.displayMessage('Erro ao adicionar alergia, confira o console apertando F12!', 'error');
                            console.error(e);
                            props.callBack();
                        })
                    } else {
                        alergiasLocal.push(dados);
                        snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                    }
                    props.setAlergias(alergiasLocal);
                    setIsOpen(false);
                } else {
                    let alergiasLocal = [...props.alergias];
                    dados.index_terapia = alergiasLocal.length;
                    if (props.paciente_id === undefined || props.paciente_id === null) {
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = alergiasLocal.length;
                        }
                        editAlergiasPaciente(props.modalData, dados)
                        setIsOpen(false);
                        snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                    } else {
                        await api.put('api/paciente-alergia/' + props.modalData.id, dados).then(() => {
                            /* MedicamentosPacienteService.update(dados, props.modalData.id).then(() => { */
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            setIsOpen(false);
                            props.callBack();
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar atualizar o cadastro!', '', 'error');
                        })
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Houve um problema ao tentar cadastrar!", '', 'error');
        } finally {

        }
    }

    useEffect(() => {
        //getDefaultAlergia(alergiasList);
    }, [props.modalData])

    function functionThatSubmitsForm() {
        formRef.current.submitForm()
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root')}
                transparent
            >
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold", textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>
                        {props.modalData === null ? "Alergias do(a) Paciente" : "Alterar Alergias do(a) Paciente"}
                    </h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", }}>
                                <div style={{ width: "100%", paddingRight: 10 }}>
                                    <Input title="Alergias:" name="alergia" id="alergia" defaultValue={props.modalData === null ? '' : props.modalData.alergia} />
                                </div>
                            </div>
                        </div>
                        <button className='btn-padrao' type="button" onClick={functionThatSubmitsForm}>
                            {props.modalData === null ? "Adicionar" : "Alterar"}
                        </button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(ModalAlergias);