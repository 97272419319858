import React from 'react';
import PropTypes from 'prop-types';
import { RiLockLine } from 'react-icons/ri';
import styled from 'styled-components';

const LockAndLine = styled.div`
    position: relative;
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    hr {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        border-width: 4px;
    }

    .lock-icon {
        position: relative;
        z-index: 2;
        background: rgb(246, 246, 246);
        padding: 0;
        margin: 30px auto 0px auto;
    }
`;

const BlockedAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
`;

const BlockedRegion = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const BlockedArea = ({ text, blockStatus, children }) => {
    if (blockStatus) {
        return (
            <BlockedAreaContainer>
                <LockAndLine>
                    <RiLockLine size={25} className='font-roxo lock-icon' />
                    <hr className='font-roxo'></hr>
                </LockAndLine>
                <span className='font-roxo'>{text}</span>
            </BlockedAreaContainer>
        );
    } else {
        return children;
    }
};

BlockedArea.propTypes = {
    text: PropTypes.string.isRequired,
};

export default BlockedArea;