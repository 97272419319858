import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the current window width is considered mobile.
 * 
 * @returns {boolean} - Returns `true` if the window width is less than 768 pixels or if the device is an iPhone, otherwise `false`.
 * 
 * @example
 * const isMobile = useIsMobile();
 * console.log(isMobile); // true or false based on the window width or if the device is an iPhone.
 * 
 * @function
 * @name useIsMobile
 * 
 * @description
 * This hook sets an initial state based on the window's inner width. It adds an event listener to the window's resize event to update the state whenever the window is resized. Additionally, it checks if the user agent is an iPhone and sets the state to true if so.
 * 
 * @hook
 * @returns {boolean} - The current state indicating if the device is considered mobile.
 */
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        const isIphone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
        if (isIphone) {
            setIsMobile(true);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;