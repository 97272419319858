import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import { Form } from '@unform/web';
import Input from "../../../../../components/Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import InputMask from '../../../../../components/InputMask';
import { MedicamentosPacienteService } from '../../../../../services/EmergenciasPacienteService';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: "auto",
        width: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};
const ModalMedicamentos = (props, ref) => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [alergiaMedicamento, setAlergiaMedicamento] = useState(null);
    const formRef = useRef(null);
    const snackbar = useSnackbar();
    const alergiaIsTrue = {
        width: '50%',
        marginRight: 10,
    };
    const alergiaISFalse = {
        width: '100%',
    }
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);
    const alergiasList = [
        { label: 'Sim', value: true },
        { label: 'Não', value: false },
    ]
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    const getDefaultAlergia = (alergia) => {
        if (!props.modalData) return;
        const alergiaPaciente = alergia.filter(data => {
            return data.value === props.modalData.medicamentos_alergia;
        })[0];
        setAlergiaMedicamento(alergiaPaciente);
    }
    const editNewPacienteEmergencias = (medicamento, dados) => {
        let medicamentosLocal = [...props.medicamentos];
        const index = medicamentosLocal.indexOf(medicamento);
        medicamentosLocal.splice(index, 1, dados);
        props.setMedicamentos(medicamentosLocal);
    }
    async function handleSubmit(data) {
        try {
            if (data.medicamentos === '' || data.medicamentos_horarios === '' || data.medicamentos_alergia === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning')
            } else {
                const dados = {
                    medicamento: data.medicamentos,
                    horario: data.medicamentos_horarios,
                    alergias: data.medicamentos_alergias,
                };
                if (props.modalData === null) {
                    let medicamentosLocal = [...props.medicamentos];
                    if (props.paciente_id !== undefined && props.paciente_id !== null) {
                        dados.paciente_id = props.paciente_id;
                        await MedicamentosPacienteService.store(dados).then(({ data }) => {
                            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                            medicamentosLocal.push(data);
                        }).catch((e) => {
                            Swal.fire("Erro ao cadastrar", "Não foi possível cadastrar o medicamento do paciente, aperte F12 e confira o log do erro.", "success");
                            console.error(e);
                        })
                    } else {
                        medicamentosLocal.push(dados);
                    }
                    props.setMedicamentos(medicamentosLocal);
                    setIsOpen(false);
                } else {
                    let medicamentosLocal = [...props.medicamentos];
                    dados.index_terapia = medicamentosLocal.length;
                    if (props.paciente_id === undefined || props.paciente_id === null) {
                        if (dados.index_terapia === undefined) {
                            dados.index_terapia = medicamentosLocal.length;
                        }
                        editNewPacienteEmergencias(props.modalData, dados)
                        snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                        setIsOpen(false);
                    } else {
                        MedicamentosPacienteService.update(dados, props.modalData.id).then(() => {
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            for (let index = 0; index < medicamentosLocal.length; index++) {
                                const e = medicamentosLocal[index];
                                if (e.id == props.modalData.id) {
                                    medicamentosLocal[index] = { ...dados, id: props.modalData.id };
                                    break;
                                }
                            }
                            props.setMedicamentos(medicamentosLocal);
                            setIsOpen(false);
                        }).catch((e) => {
                            Swal.fire('Erro ao alterar registro!', 'O registro do medicamento não pode ser alterado, aperte F12 para conferir o erro.', 'error');
                            console.error(e);
                        })
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Houve um problema ao tentar cadastrar!", '', 'error');
        } finally {
        }
    }
    useEffect(() => {
        //getDefaultAlergia(alergiasList);
    }, [props.modalData])
    function functionThatSubmitsForm() {
        formRef.current.submitForm()
    };
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                appElement={document.getElementById('root')}
                transparent
            >
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold", textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>
                        {props.modalData === null ? "Medicamentos do(a) Paciente" : "Alterar Medicamentos do(a) Paciente"}
                    </h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "50%", paddingRight: 10 }}>
                                    <Input title="Medicamento(s) em Uso:" name="medicamentos"
                                        defaultValue={props.modalData === null ? '' : props.modalData.medicamento} />
                                </div>
                                <div style={{ width: "50%", paddingRight: 10 }}>
                                    <Input title="Horario:" name="medicamentos_horarios"
                                        defaultValue={props.modalData === null ? '' : props.modalData.horario} />
                                </div>
                            </div>
                        </div>
                        <button className='btn-padrao' type="button" onClick={functionThatSubmitsForm}> {props.modalData === null ? "Adicionar" : "Alterar"} </button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}
export default forwardRef(ModalMedicamentos);
