import React, { useEffect, useRef, useState, useCallback, forwardRef, } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import ReactQuill from 'react-quill';

import { Container, Error } from './styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';


const Input = forwardRef(({ name, title, mask, containerStyle = {}, upperContainerStyle = {}, labelClassName = '', icon: Icon, removeStlyes = false, sideContent, upperContainerClassName, containerClassName, required, ...rest }, ref) => {
  //#region VARIAVEIS
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  //#endregion
  //#region ESTILOS
  const sideContainerStyle = sideContent ? { padding: '14px 0px 14px 14px' } : {};
  containerStyle = removeStlyes
    ? {}
    : {
      ...containerStyle,
      ...sideContent ? sideContainerStyle : {},
      marginTop: 0,
      backgroundColor: rest.disabled ? '#ececec' : '#FFF',
    };
  const sideContentStyle = sideContent ? { width: '80%' } : {};
  const styleInput = removeStlyes
    ? {}
    : {
      ...rest.style,
      ...sideContentStyle,
      color: rest.disabled ? '#545454' : '#000',
      borderRadius: '8px'
    };
  //#endregion
  //#region HANDLES
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);
  //#endregion
  //#region USE EFFECT
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  //#endregion
  //#region HTML
  return (
    <div className={upperContainerClassName} style={!upperContainerStyle ? { display: 'flex', flexDirection: "column", width: '100%' } : upperContainerStyle}>
      {(!rest.noLabel && title) && <label className={labelClassName} style={{ fontWeight: "negrito", marginTop: 10, fontSize: 13 }}><b style={{ color: "orange" }}>{required ? '*' : ''}</b>{title}</label>}
      <Container
        className={containerClassName}
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid="input-container"
      >
        {Icon && <Icon size={20} />}
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          style={styleInput}
          autoComplete='off'
          required={required}
          {...rest}
        />
        {sideContent ? sideContent : null}
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
  //#endregion
});

const InputPlusButton = ({ name, title, containerStyle = {}, upperContainerStyle = {}, icon: Icon, removeStlyes = false, button, sideContent, ...rest }) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const sideContainerStyle = sideContent ? { padding: '14px 0px 14px 14px' } : {};
  containerStyle = removeStlyes
    ? {}
    : {
      ...containerStyle,
      ...sideContent ? sideContainerStyle : {},
      marginTop: 0,
      backgroundColor: rest.disabled ? '#ececec' : '#FFF',
    };
  const sideContentStyle = sideContent ? { width: '80%' } : {};
  const styleInput = removeStlyes
    ? {}
    : {
      ...rest.style,
      ...sideContentStyle,
      paddingLeft: '10px',
      color: rest.disabled ? '#545454' : '#000',
      borderRadius: '8px'
    }

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div style={!upperContainerStyle ? { display: 'flex', flexDirection: "column", width: '100%' } : upperContainerStyle}>
      {!rest.noLabel && <label style={{ fontWeight: "negrito", marginTop: 10, fontSize: 13 }}>{title}</label>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        className='input-group p-0 m-0'
        data-testid="input-container"
      >
        <input
          className='position-relative flex-1-1-auto w-1per min-w-0'
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          style={styleInput}
          autoComplete='off'
          {...rest}
        />
        <div className='input-group-text' style={{ margin: '0px', padding: '0px ', height: '40px', borderLeft: 'none' }}>
          {button}
        </div>
        {sideContent ? sideContent : null}
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
};

function SimpleInput({ name, id, labelClass, labelStyle = { fontWeight: 'negrito', marginTop: '10px', fontSize: '13px' }, containerClass, containerStyle, style = { marginLeft: '15px' }, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    rest.title
      ? <div className={containerClass} style={containerStyle}>
        <label htmlFor={id} className={labelClass} style={labelStyle}>{rest.title}</label>
        <input id={id} ref={inputRef} defaultValue={defaultValue} style={style} autoComplete='off' {...rest} />
      </div>
      : <input id={id} ref={inputRef} defaultValue={defaultValue} style={style} autoComplete='off' {...rest} />
  );
}

function TextAreaInput({ name, title = null, rows = 6, classDiv,
  containerStyle = {
    display: 'flex', flexDirection: 'column', width: '100%'
  },
  labelStyle = {
    fontWeight: 'negrito', marginTop: 10, fontSize: 13
  },
  style = {
    borderRadius: '8px'
  },
  ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div className={classDiv} style={containerStyle}>
        {title && <label htmlFor={name} style={labelStyle}>{title}</label>}
        <textarea name={name} ref={inputRef} defaultValue={defaultValue} style={style} rows={rows} {...rest} />
      </div>
    </>
  );
}

function RichTextEditor({ name, title = null, classDiv,
  containerStyle = {
    display: 'grid',
    flexDirection: 'column',
    width: '100%'
  },
  labelStyle = {
    fontWeight: 'negrito',
    marginTop: 10,
    fontSize: 13
  },
  ...rest
}) {
  //#region VARIAVEIS
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  //#endregion

  //#region USE EFFECTS
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  //#endregion

  //#region HTML
  return (
    <div className={classDiv} style={containerStyle}>
      {title && <label htmlFor={name} style={labelStyle}>{title}</label>}
      <ReactQuill
        theme="snow"
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
  //#endregion
}

function InputArquivo({ name, title = 'Anexar Documento', path, containerStyle = {}, icon: Icon, removeStlyes = false, handleChange, handleDeleteUpload, ...rest }) {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  containerStyle = removeStlyes ? {} : {
    ...containerStyle,
    marginTop: 0,
    backgroundColor: rest.disabled ? '#ececec' : '#FFF',
  };

  const UploadIcon = {
    border: "none",
    borderRadius: 8,
  };

  const LabelUpload = {
    color: '#7340BD',
    cursor: "pointer"
  };

  const Upload = {
    color: '#7340BD',
    cursor: "pointer",
    fontFamily: "Nunito, sans-serif",
    display: "flex",
    height: 25,
  };

  const Delete = {
    color: '#FFFFFF',
    cursor: "pointer",
    backgroundColor: '#7340BD',
    fontFamily: "Nunito, sans-serif",
    height: 30,
    marginLeft: 10,
    borderRadius: 20,
    padding: "2px 8px",
    display: "flex",
    justifyContent: "center",
  };

  const InputUpload = {
    borderRadius: 8,
    height: 30,
    display: "none"
  };

  const ShowStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    marginBottom: 20
  };

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      data-testid="input-container"
    >
      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", }}>
        <div style={{ marginLeft: 5, marginRight: 10, }}>
          <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
        </div>
        <div style={{ flexDirection: "column" }}>
          <label htmlFor={name} style={LabelUpload}>{title}
            <input
              type="file"
              id={name}
              style={InputUpload}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={handleChange}
              defaultValue={defaultValue}
              ref={inputRef}
              autoComplete='off'
              {...rest}
            />
          </label>
        </div>
      </div>
      <div style={ShowStyle}>
        {Array.isArray(path)
          ? path.map((item) => {
            return <span style={Upload}>{item}
              <div>
                <span style={Delete} onClick={handleDeleteUpload}> x </span>
              </div>
            </span>
          })
          : path && <span style={Upload}>{path}
            <div>
              <span style={Delete} onClick={handleDeleteUpload}> x </span>
            </div>
          </span>
        }
      </div>

      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </div>
  );
}
export default Input;
export { SimpleInput, TextAreaInput, RichTextEditor, InputArquivo, InputPlusButton };