import Swal from 'sweetalert2';
import api from './api';
import { useSnackbar } from 'context/snackbar';
import moment from 'moment';

class SolicitacaoDeAlteracaoService {
  snackbar = useSnackbar();
  apiUrl = 'api/solicitacao-alteracao';
  static index = (params) => {
    return api.get(`${this.apiUrl}?${params}`);
  };
  static store = (obj) => {
    return api.post(this.apiUrl, obj);
  };
  static show = (id) => {
    return api.get(`${this.apiUrl}/${id}`);
  };
  static update = (obj, id) => {
    return api.put(`${this.apiUrl}/${id}`, obj);
  };
  static destroy = (id) => {
    return api.delete(`${this.apiUrl}/${id}`);
  };
  static aprovaReprova = (solicitacao, aprovaResponse = () => { }, reprovaResponse = () => { }, response = () => { }) => {
    Swal.fire({
      title: 'Solicitação de Alteração do Atendimento',
      icon: 'info',
      html: `
        <div class="container">
          <div class="row mb-2">
            <div class="col-4 text-left">
              <b>Solicitante:</b>
            </div>
            <div class="col-8 text-left">
              ${solicitacao?.user?.name || ''}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4 text-left">
              <b>Solicitado em:</b>
            </div>
            <div class="col-8 text-left">
              ${moment(solicitacao.created_at).format('DD/MM/YYYY HH:mm')}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4 text-left">
              <b>Descrição:</b> 
            </div>
            <div class="col-8 text-left">
              ${solicitacao?.descricao || ''}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4 text-left">
              <b>Motivo:</b> 
            </div>
            <div class="col-8 text-left">
              ${solicitacao?.motivo || ''}
            </div>
          </div>
          ${solicitacao?.status === 'Reprovado' ? `
          <div class="row mb-2">
            <div class="col-4 text-left">
              <b>Motivo da Reprovação:</b> 
            </div>
            <div class="col-8 text-left">
              ${solicitacao?.motivo_reprovacao || ''}
            </div>
          </div>
          ` : ''}
        </div>
      `,
      showDenyButton: true,
      confirmButtonText: 'Aprovar',
      allowOutsideClick: true,
      denyButtonText: `Reprovar`
    }).then((result) => {
      if (result.isConfirmed) {
        api.patch('api/solicitacao-alteracao/' + solicitacao.id + '?id=' + solicitacao.id, { status: 'Aprovado' }).then(async () => {
          aprovaResponse();
        }).catch((error) => {
          console.error(error);
          this.snackbar.displayMessage("Erro ao aprovar solicitação de alteração!", "error");
        });
      } else if (result.isDenied) {
        Swal.fire({
          title: 'Qual o motivo da reprovação?',
          input: 'text',
          inputLabel: 'Motivo',
          inputPlaceholder: 'Digite o motivo da reprovação...',
          showCancelButton: true,
          confirmButtonText: 'Reprovar',
          showLoaderOnConfirm: true,
          preConfirm: (motivo) => {
            if (motivo === '') {
              Swal.showValidationMessage('Motivo não pode ser vazio.');
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            api.patch('api/solicitacao-alteracao/' + solicitacao.id + '?id=' + solicitacao.id, { status: 'Reprovado', motivo_reprovacao: result.value }).then(() => {
              reprovaResponse();
            }).catch((error) => {
              console.error(error);
              this.snackbar.displayMessage("Erro ao reprovar solicitação de alteração!", "error");
            });
          }
        });
      }
      response();
    });
  }
  static visualizar = (solicitacao) => {
    Swal.fire({
      title: `Solicitação de Alteração do Atendimento ${moment(solicitacao.created_at).format('HH:mm')}`,
      icon: 'info',
      html: `
        <div class="container">
            <div class="row mb-2">
              <div class="col-4 text-left">
                <b>Solicitante:</b>
              </div>
              <div class="col-8 text-left">
                <span>${solicitacao?.user?.name || ''}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 text-left">
                <b>Solicitado em:</b>
              </div>
              <div class="col-8 text-left">
                <span>${moment(solicitacao.created_at).format('DD/MM/YYYY HH:mm')}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 text-left">
                <b>Descrição:</b>
              </div>
              <div class="col-8 text-left text-break">
                <span>${solicitacao?.descricao || ''}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4 text-left">
                <b>Motivo:</b>
              </div>
              <div class="col-8 text-left text-break">
                <span>${solicitacao?.motivo || ''}</span>
              </div>
            </div>
            ${solicitacao?.status === 'Reprovado' ? `
              <div class="row mb-2">
                <div class="col-4 text-left">
                  <b>Motivo da Reprovação:</b>
                </div>
                <div class="col-8 text-left text-break">
                  <span>${solicitacao?.motivo_reprovacao || ''}</span>
                </div>
              </div>
            ` : ''}
        </div>
      `,
      confirmButtonText: 'Fechar'
    });
  }
}

export default SolicitacaoDeAlteracaoService;