import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { TableFuncionarios } from "./TableFuncionarios";
import FuncionarioService from "../../../services/FuncionarioService";
import FuncionariosModal from "../../../components/Modais/FuncionariosModal";
import { FiFilter } from "react-icons/fi";
import { useAuth } from "../../../context/auth";
import api from "services/api";
import { ChangeCase } from "services/ChangeCase";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Funcionarios() {
    const [loading, setLoading] = useState(true);
    const [color] = useState("#8ed4ae");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Funcionarios, setFuncionarios] = useState([]);
    const [funcionariosOriginal, setFuncionariosOriginal] = useState([]);
    const [modalData, setModalData] = useState();
    const [filterName, setFilterName] = useState("");
    const [tiposUser, setTiposUser] = useState([]);
    const [filterTipo, setFilterTipo] = useState("");
    const [funcionarioId, setFuncionarioId] = useState(null);
    const usuario = useAuth();
    const [permissoes, setPermissoes] = useState([]);
    const coletaPermissoes = () => {
        let permissoes = usuario.user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }

    const coletaTiposUser = () => {
        api.get('api/group?order_by=name:asc')
            .then(response => {
                if (response.data) {
                    const data = response.data;
                    const tiposUser = data.map((tipo) => {
                        return {
                            value: ChangeCase.toLowerCase(tipo.name),
                            label: ChangeCase.toUpperCase(tipo.name),
                        }
                    });
                    setTiposUser(tiposUser);
                }
            })
    }

    const loadFuncionarios = async () => {
        setFuncionarioId(null);
        await FuncionarioService.getByTipoUser('with=documentos_gerais&select=id,tipo_user,name,email,status,telefone&sort=name&tipo=!terapeuta,responsavel')
            .then(({ data }) => {
                setFuncionarios(data);
                setFuncionariosOriginal(data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }

    const filtrar = () => {
        if (filterName !== '' && filterTipo !== '') {
            let newFiltros = funcionariosOriginal;
            newFiltros.filter((e) => {
                return e.name.toLowerCase().includes(filterName.toLowerCase()) && e.tipo_user === filterTipo;
            })
            setFuncionarios(newFiltros);
        } else {
            let allVasil = true;
            if (filterName !== '') {
                setFuncionarios(funcionariosOriginal.filter((e) => {
                    return e.name.toLowerCase().includes(filterName.toLowerCase());
                }));
            } else {
                if (filterTipo !== '') {
                    setFuncionarios(funcionariosOriginal.filter((e) => {
                        return e.tipo_user.toLowerCase() === filterTipo.toLowerCase();
                    }));
                } else {
                    if (allVasil) {
                        setFuncionarios(funcionariosOriginal);
                    }
                }
            }
        }
    }

    const handleCloseFuncionario = () => {
        setIsOpen(false);
        loadFuncionarios();
    }

    const handleNewFuncionário = () => {
        setModalData(null);
        setIsOpen(true);
    }

    useEffect(() => {
        if (funcionariosOriginal) {
            filtrar();
        }
    }, [filterTipo, filterName, funcionariosOriginal]);
    useEffect(() => {
        loadFuncionarios();
        coletaPermissoes();
        coletaTiposUser();
    }, []);

    return (
        <Container>
            <div className="row">
                <div className="col-12 div-titulo mt-2">
                    <label className="titulo">Funcionários</label>
                </div>
                <div className="col-6 d-flex align-center">
                    <div className="w-100per">
                        <label className="label-filtro">Filtrar por Nome:</label>
                        <input className="input-filtro w-100per mt-20px" placeholder={"Pesquisar Funcionário por Nome:"} value={filterName}
                            onChange={(e) => {
                                setFilterName(e.target.value);
                            }}
                            onFocus={(e) => {
                                e.target.select()
                            }} />
                    </div>
                </div>
                <div className="col-6 d-flex align-center">
                    <div className="w-90per">
                        <label className="label-filtro">Filtrar por Tipo de Usuário:</label>
                        <select className="select-filtro h-38px w-90per mt-20px" value={filterTipo} onChange={(e) => setFilterTipo(e.target.value)}>
                            <option value="">Todos</option>
                            {tiposUser && tiposUser.map((tipo) => {
                                return <option value={tipo.value}>{tipo.label}</option>
                            })};
                        </select>
                    </div>
                    <div className="w-10per">
                        <button className='btn-padrao h-38px w-100per mt-50px' title='Filtrar' onClick={(e) => { filtrar() }}> <FiFilter /> </button>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <div style={{ marginBottom: 50 }}>
                {(Funcionarios.length !== 0 && loading === false)
                    ? <TableFuncionarios callback={loadFuncionarios} source={Funcionarios} openModal={setIsOpen} setFuncionarioId={setFuncionarioId} permissoes={permissoes} />
                    : <ClipLoader color={color} loading={loading} css={override} size={50} />
                }
            </div>
            {permissoes['criação'] &&
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <button onClick={() => handleNewFuncionário()} className="btn-padrao">
                        <AiOutlinePlusCircle style={{ marginRight: 5 }} />
                        Novo funcionário
                    </button>
                </div>}
            {modalIsOpen
                && <FuncionariosModal setIsOpen={setIsOpen} callback={loadFuncionarios} funcionarioId={funcionarioId} onClose={handleCloseFuncionario} />
            }
        </Container>
    )
}