import React, { useState } from 'react';
import { ContainerCard, ContainerSubcard, HeaderCard, PacienteDescricao, PacienteNome } from './styles';
import moment from 'moment/moment';
import ProntuarioSubCard from './ProntuarioSubCard';
import { FaRegEdit } from 'react-icons/fa';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { RiFileSearchLine, RiFolderAddLine } from 'react-icons/ri';
import { VscGraphLine } from 'react-icons/vsc';
import { CgFileDocument } from 'react-icons/cg';
import PacienteAltasModal from 'components/Modais/PacienteAltasModal';
import PacienteRelatoriosModal from 'components/Modais/PacienteRelatoriosModal';
import PacienteLaudosModal from 'components/Modais/PacienteLaudosModal';
import PacienteEvolucaoDiariaModal from 'components/Modais/PacienteEvolucaoDiariaModal';
import PacientePlanoTerapeuticoModal from 'components/Modais/PacientePlanoTerapeuticoModal';
import PacienteInformacoesComplementaresModal from 'components/Modais/PacienteInformacoesComplementaresModal';

function ProntuarioCard({ paciente }) {
    const [openModalAltas, setOpenModalAltas] = useState(false);
    const [openModalRelatorios, setOpenModalRelatorios] = useState(false);
    const [openModalLaudos, setOpenModalLaudos] = useState(false);
    const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
    const [openModalPlanoTerapeutico, setOpenModalPlanoTerapeutico] = useState(false);
    const [openModalOutrosDocs, setOpenModalOutrosDocs] = useState(false);

    return (
        <ContainerCard style={{ boxShadow: "0 0 10px 0px silver" }}>
            <HeaderCard>
                <PacienteNome>
                    {paciente?.nome}
                </PacienteNome>
                <PacienteDescricao title={moment(paciente?.nascimento).format('DD/MM/YYYY')}>
                    {paciente?.nascimento === null ? "Erro: Sem data cadastrada" : moment().diff(paciente?.nascimento, 'years') + " anos"}
                </PacienteDescricao>
                <ContainerSubcard>
                    <ProntuarioSubCard onClick={() => setOpenModalAltas(true)} nome='Altas' icone={<FaRegEdit />} disabled={true} />
                    <ProntuarioSubCard onClick={() => setOpenModalRelatorios(true)} nome='Relatórios' icone={<HiOutlineNewspaper />} disabled={true} />
                    <ProntuarioSubCard onClick={() => setOpenModalLaudos(true)} nome='Laudos' icone={<RiFileSearchLine />} disabled={true} />
                    <ProntuarioSubCard onClick={() => setOpenModalEvolucao(true)} nome='Evolução' icone={<VscGraphLine />} />
                    <ProntuarioSubCard onClick={() => setOpenModalPlanoTerapeutico(true)} nome='Plano Terapêutico' icone={<RiFolderAddLine />} disabled={true} />
                    <ProntuarioSubCard onClick={() => setOpenModalOutrosDocs(true)} nome='Outros Documentos' icone={<CgFileDocument />} disabled={true} />
                </ContainerSubcard>
            </HeaderCard>
            {openModalAltas && <PacienteAltasModal onClose={() => setOpenModalAltas(false)} paciente={paciente} />}
            {openModalRelatorios && <PacienteRelatoriosModal onClose={() => setOpenModalRelatorios(false)} paciente={paciente} />}
            {openModalLaudos && <PacienteLaudosModal onClose={() => setOpenModalLaudos(false)} paciente={paciente} />}
            {openModalEvolucao && <PacienteEvolucaoDiariaModal onClose={() => setOpenModalEvolucao(false)} paciente={paciente} />}
            {openModalPlanoTerapeutico && <PacientePlanoTerapeuticoModal onClose={() => setOpenModalPlanoTerapeutico(false)} paciente={paciente} />}
            {openModalOutrosDocs && <PacienteInformacoesComplementaresModal onClose={() => setOpenModalOutrosDocs(false)} paciente={paciente} />}
        </ContainerCard>
    );
}

export default ProntuarioCard;
