import React, { useMemo } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { TableDefault } from '../../../../../components/TableDefault';
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import api from "../../../../../services/api";

export function TableAlergiasPaciente({ source, setModalAlergiasData, setAlergias, callBack, handleOpen, readOnly = false }) {

    const snackbar = useSnackbar();

    const removeAlergia = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover esta alergia?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (data.paciente_id === null || data.paciente_id === undefined) {
                    let alergiasLocal = [...source];
                    const index = alergiasLocal.indexOf(data);
                    alergiasLocal.splice(index, 1);
                    setAlergias(alergiasLocal);
                    callBack()
                } else {
                    api.delete(`api/paciente-alergia/${data.id}`)
                    callBack()
                }
                snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    }

    const handleEditEmergencia = (data) => {
        setModalAlergiasData(data);
        handleOpen();
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => readOnly ? [
            {
                Header: 'Alergias do Paciente:',
                accessor: 'alergia',
            }
        ] : [
            {
                Header: 'Alergias do Paciente:',
                accessor: 'alergia',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        return (
                            <div className="d-flex align-items-center justify-content-center m-2">
                                <AiOutlineEdit className="icone-padrao" title={cell.row.original.id} onClick={e => handleEditEmergencia(cell.row.original)} />
                                <AiOutlineDelete className="icone-padrao" title={cell.row.original.id} onClick={e => removeAlergia(cell.row.original)} />
                            </div>
                        )
                    }
                }
            }
        ],
        [source]
    )

    return (
        <TableDefault columns={columns} source={data} />
    );
}
