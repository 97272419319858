import React, { useState, useRef, useEffect } from 'react';

const DropdownCSS = ({ button, substituicao, reserva, content, clickMode = false, buttonOver = false, defaultOpen = false, dropdownZIndex, setMostrando = () => { } }) => {
    let timeoutId;
    const [isHovered, setIsHovered] = useState(defaultOpen ? true : false);
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => {
        console.log('handleMouseEnter');
        clearTimeout(timeoutId);
        setMostrando(true);
    };
    const handleMouseLeave = () => {
        console.log('handleMouseLeave');
        timeoutId = setTimeout(() => {
            setMostrando(false);
        }, 200);
    };

    useEffect(() => {
        setMostrando(isHovered);
    }, [isHovered]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsHovered(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const getDropdownPositionClass = () => {
        if (substituicao) {
            return 'ns-dropdown-menu-right';
        } else if (reserva) {
            return 'ns-dropdown-menu-left';
        }
        return 'ns-dropdown-menu-default';
    };

    return (
        <div className={`ns-dropdown-parent-css`} ref={dropdownRef} >
            <div type="button" className={`ns-dropdown-btn-css p-0 m-0`}>
                {button}
            </div>
            <div
                className={`ns-dropdown-menu-css ${getDropdownPositionClass()}`}>
                {content}
            </div>
        </div>
    );
};

export default DropdownCSS;