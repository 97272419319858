import React, { useEffect, useState, useRef } from 'react';

const InactivityTimeout = ({ timeoutDuration = 300000, onTimeout }) => {
  const [isActive, setIsActive] = useState(true);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    setIsActive(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsActive(false);
      onTimeout();
    }, timeoutDuration);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll'];
    const handleActivity = () => resetTimeout();

    const shouldStartTimer = window.location.pathname !== '/offline-testes' &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/';
    //console.log('shouldStartTimer', shouldStartTimer);
    if (shouldStartTimer) {
      events.forEach(event => window.addEventListener(event, handleActivity));
      resetTimeout();
    }

    return () => {
      if (shouldStartTimer) {
        events.forEach(event => window.removeEventListener(event, handleActivity));
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    };
  }, [timeoutDuration, onTimeout]);

  return null;
};

export default InactivityTimeout;