import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContainerButton, HomeIcon } from '../styles';
import { ConfigIcon } from '../../HeaderRecepcao/styles';
import { FaCalendarAlt, FaHeartbeat, FaUserAlt, FaUserMd, FaUserTie } from "react-icons/fa";
import { AiOutlineException } from 'react-icons/ai';
import { BsBellFill, BsList } from 'react-icons/bs';
import { NotificacaoPendenteP } from '../../HeaderTerapeuta/styles';
import { MdOutlineAssignment } from 'react-icons/md';

const NavLink = ({ link, name, icon, placeholder = null, atualLocal, notificacao = 0, dropDownList = null, clickable = true }) => {
    const selected = {
        backgroundColor: '#5c3498',
    };
    const unselected = {};
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [isTouchDevice, setIsTouchDevice] = React.useState(false);
    const [altura, setAltura] = React.useState(0);

    const getIcon = (icon) => {
        switch (icon) {
            case ('home'):
                return <HomeIcon />
            case ('paciente'):
                return <FaUserAlt />
            case ('config'):
                return <ConfigIcon />
            case ('terapeuta'):
                return <FaUserMd />
            case ('funcionario'):
                return <FaUserTie />
            case ('plano'):
                return <FaHeartbeat />
            case ('listaespera'):
                return <BsList />
            case ('agenda'):
                return <FaCalendarAlt />
            case ('agenda_terapeuta'):
                return <FaCalendarAlt />
            case ('controle'):
                return <FaCalendarAlt />
            case ('guias'):
                return <AiOutlineException />
            case ('relatorios'):
                return <MdOutlineAssignment />
            case ('notificacao'):
                return <BsBellFill />
            default:
                return <HomeIcon />
        }
    }

    const handleMouseEnter = () => {
        setDropdownOpen(true);
    }

    const handleMouseLeave = () => {
        setDropdownOpen(false);
    }

    const handleClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const checkIfTouchDevice = () => {
            return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        };
        setIsTouchDevice(checkIfTouchDevice());
    }, []);

    useEffect(() => {
        if (dropDownList) {
            dropDownList.sort((a, b) => a.position - b.position);
        }
    }, [dropDownList]);

    useEffect(() => {
        if (dropdownOpen) {
            let element = document.getElementById(link);
            let altura = element?.getBoundingClientRect()?.height;
            setAltura(altura === undefined ? 0 : altura);
        }
    }, [dropdownOpen]);

    const linkContent = (
        <>
            {getIcon(icon)}
            <span>{name}</span>
        </>
    );

    if (isTouchDevice) {
        return (
            <div className='text-decoration-none align-center justify-center'
                placeholder={placeholder}
                onClick={handleClick}>
                <ContainerButton style={link.split('/')[3] === atualLocal ? selected : unselected}>
                    {notificacao > 0 &&
                        <NotificacaoPendenteP> {notificacao} </NotificacaoPendenteP>
                    }
                    {clickable ? (
                        <Link to={link} className='d-flex flex-col text-center w-100 text-decoration-none align-center justify-center font-branca'>
                            {linkContent}
                        </Link>
                    ) : (
                        <div className='d-flex flex-col text-center w-100 text-decoration-none align-center justify-center font-branca'>
                            {linkContent}
                        </div>
                    )}
                    {(dropDownList !== null) &&
                        <ul id={link} className='dropdown-menu w-fit-content bg-roxo-dark-i' style={dropdownOpen ? { marginTop: `${altura + 35}px`, display: 'block' } : { display: 'none' }} aria-labelledby="dropdownMenuButton">
                            {dropDownList.map((item, index) => (
                                <Link key={index} to={item.link} className='li-padrao font-branca hl-white-bg-laranja'>
                                    {item.label}
                                </Link>
                            ))}
                        </ul>
                    }
                </ContainerButton>
            </div >
        );
    } else {
        return (
            <div className='text-decoration-none align-center justify-center'
                placeholder={placeholder}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <ContainerButton style={link.split('/')[3] === atualLocal ? selected : unselected}>
                    {notificacao > 0 &&
                        <NotificacaoPendenteP> {notificacao} </NotificacaoPendenteP>
                    }
                    {clickable ? (
                        <Link to={link} className='d-flex flex-col text-center w-100 text-decoration-none align-center justify-center font-branca'>
                            {linkContent}
                        </Link>
                    ) : (
                        <div className='d-flex flex-col text-center w-100 text-decoration-none align-center justify-center font-branca'>
                            {linkContent}
                        </div>
                    )}
                    {(dropDownList !== null) &&
                        <ul id={link} className='dropdown-menu w-fit-content bg-roxo-dark-i' style={dropdownOpen ? { marginTop: `${altura + 35}px`, display: 'block' } : { display: 'none' }} aria-labelledby="dropdownMenuButton">
                            {dropDownList.map((item, index) => (
                                <Link key={index} to={item.link} className='li-padrao font-branca hl-white-bg-laranja'>
                                    {item.label}
                                </Link>
                            ))}
                        </ul>
                    }
                </ContainerButton>
            </div >
        );
    }
};

export default NavLink;
