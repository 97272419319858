import { Route } from 'react-router-dom';
import Login from './../pages/Login';
import Dashboard from './../pages/RecepcaoDashboard/Dashboard';
import PerfilPaciente from './../pages/RecepcaoDashboard/Pacientes/Perfil';
import NovoTerapeuta from './../pages/RecepcaoDashboard/Terapeutas/Novo';
import GrupoPermissao from './../pages/AdminDashboard/Configuracoes/Permissoes';
import PlanoDeSaude from './../pages/RecepcaoDashboard/PlanoDeSaude';
import TerapeutaDashboard from './../pages/TerapeutaDashboard/Dashboard';
import StatusDiario from './../pages/TerapeutaDashboard/StatusDiario';
import Atendimentos from './../pages/AdminDashboard/Atendimentos';
import Funcionarios from './../pages/AdminDashboard/Funcionarios';
import ListagemPacientes from './../pages/AdminDashboard/Pacientes';
import TiposAtendimento from './../pages/AdminDashboard/Configuracoes/TiposAtendimento';
import Terapias from './../pages/AdminDashboard/Configuracoes/Terapias';
import Especialidade from './../pages/AdminDashboard/Configuracoes/Especialidade';
import Modalidade from './../pages/AdminDashboard/Configuracoes/Modalidade';
import Sala from './../pages/AdminDashboard/Configuracoes/Sala';
import Estacao from './../pages/AdminDashboard/Configuracoes/Estacao';
import Documentos from './../pages/AdminDashboard/Configuracoes/Documentos';
import AreaCompartilhada from './../pages/AdminDashboard/Configuracoes/AreaCompartilhada';
import Status from './../pages/AdminDashboard/Configuracoes/Status';
import AgendaSemanal from './../pages/RecepcaoDashboard/Agenda/Terapeuta';
import AgendaAreaCompartilhada from './../pages/RecepcaoDashboard/Agenda/AreaCompartilhada';
import NovoPaciente from './../pages/RecepcaoDashboard/Pacientes/Novo';
import Guias from './../pages/RecepcaoDashboard/Guias';
import ListaEspera from './../pages/RecepcaoDashboard/Agenda/ListaDeEspera';
import AgendaPacienteId from './../pages/RecepcaoDashboard/Agenda/PacienteId';
import AgendaTerapeutaId from './../pages/RecepcaoDashboard/Agenda/TerapeutaId';
import AgendaTerapeutas from './../pages/RecepcaoDashboard/Agenda/Terapeutas';
import ControleDiario from './../pages/RecepcaoDashboard/Agenda/ControleDiario';
import DocumentosPendentes from './../pages/DocumentosPendentes';
import React, { useEffect } from 'react';
import DocumentoAssinatura from './../pages/DocumentoAssinatura';
import DocumentosAssinados from './../pages/RecepcaoDashboard/DocumentosAssinados';
import PlanosTerapeuticosPendentes from './../pages/PlanosTerapeuticosPendentes';
import Prontuarios from './../pages/RecepcaoDashboard/Pacientes/Prontuarios';
import EvolucaoDiaria from './../pages/TerapeutaDashboard/Pendencias';
import PendenciasDiarias from './../pages/TerapeutaDashboard/Pendencias/PendenciasDiarias';
import PendenciasAtrasadas from './../pages/TerapeutaDashboard/Pendencias/PendenciasAtrasadas';
import TerapeutaPacientes from './../pages/TerapeutaDashboard/TerapeutaPacientes';
import TerapeutaProntuarios from './../pages/TerapeutaDashboard/TerapeutaPacientes/Prontuarios';
import Solicitacoes from './../pages/TerapeutaDashboard/Solicitacoes';
import TerapeutaAgenda from './../pages/TerapeutaDashboard/Agenda';
import TerapeutaRelatorios from './../pages/TerapeutaDashboard/Relatorios';
import HeaderPadrao from 'layouts/HeaderPadrao';
import MinhasSolicitacoes from './../pages/TerapeutaDashboard/Solicitacoes/MinhasSolicitacoes';
import ResponsavelDashboard from './../pages/ResponsavelDashboard/Dashboard';
import TerapeutasResponsavel from './../pages/ResponsavelDashboard/Terapeutas';
import CurriculoTerapeuta from './../pages/ResponsavelDashboard/Terapeutas/CurriculoTerapeuta';
import PacientesResponsavel from './../pages/ResponsavelDashboard/Pacientes';
import DocumentosPendentesResponsavel from './../pages/ResponsavelDashboard/Dashboard/DocumentosPendentes';
import AgendaPacienteResponsavel from './../pages/ResponsavelDashboard/AgendaPaciente';
import CoordenacaoDashboard from './../pages/CoordenacaoDashboard/Dashboard';
import CadastroPerfilTerapeuta from './../pages/CoordenacaoDashboard/Terapeutas/Novo';
import PerfilPacienteResponsavel from './../pages/ResponsavelDashboard/Pacientes/Perfil';
import PerfilPacienteAdmin from './../pages/AdminDashboard/Pacientes/Perfil';
import ListagemTerapeutas from './../pages/AdminDashboard/Terapeutas';
import PlanoDeSaudeAdmin from './../pages/AdminDashboard/PlanoDeSaude';
import ConfiguracoesAdmin from './../pages/AdminDashboard/Configuracoes';
import RelatóriosEstrategicos from './../pages/AdminDashboard/RelatoriosEstrategicos';
import AuditorDashboard from './../pages/AuditorDashboard';
import PerfilPacienteAuditor from './../pages/AuditorDashboard/Paciente/Perfil';
import AvaliacaoDeMateriais from './../pages/CoordenacaoDashboard/SolicitacaoDeMateriais';
import AgendaTerapeutaAdmin from './../pages/AdminDashboard/Terapeutas/AgendaTerapeutaAdmin';
import DocumentosTerapeuticos from './../pages/ResponsavelDashboard/Dashboard/DocumentosTerapeuticos';
import FaltasPacientes from './../pages/CoordenacaoDashboard/FaltasPacientes';
import AreaCompartilhadaTerapeuta from './../pages/TerapeutaDashboard/Agenda/AreasCompartilhadas';
import TiposAvaliacao from './../pages/AdminDashboard/Configuracoes/TiposAvaliacao';
import RelatóriosCoordenacao from './../pages/CoordenacaoDashboard/RelatoriosCoordenacao';
import TerapeutaBloqueado from './../pages/TerapeutaDashboard/BloqueioAcesso';
import Pesquisar from './../pages/TerapeutaDashboard/TerapeutaPacientes/Pesquisar';
import Diagnosticos from './../pages/AdminDashboard/Configuracoes/Diagnosticos';
import ListagemDeGlosas from './../pages/AdminDashboard/Terapeutas/Glosas';
import HorariosAtendimento from './../pages/AdminDashboard/Configuracoes/HorariosAtendimento';
import Testes from './../pages/Testes';
import Testes2 from './../pages/Testes2';
import AgendamentoDashboard from './../pages/AgendamentoDashboard';
import PerfilUsuario from './../pages/Login/Perfil';
import Notificacoes from './../pages/Notificacoes';
import Recados from './../pages/Recados';
import Uploads from './../pages/Uploads';
import ActiveConnections from './../pages/WebSocketManager';
import { GraficosPacientes } from './../pages/Relatorios/Graficos/Pacientes';
import PadraoDocumento from './../pages/Parametros/PadraoDocumento';
import Postman from './../pages/Desenvolvedor/Postman';
import DashboardPadrao from './../pages/Desenvolvedor';

import TipoDemandas from 'pages/AdminDashboard/Configuracoes/TipoDemanda';
import GeradorDeDocumento from 'pages/GeradorDeDocumento';
import RelatoriosAtendimentos from 'pages/Relatorios/Atendimentos';

const AdministradorRoutes = [
    <Route path='/dashboard/administrador' element={<DashboardPadrao />} />,
    <Route path='/dashboard/administrador/agenda' element={<HeaderPadrao><AgendaSemanal /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/agenda/controlediario' element={<HeaderPadrao><ControleDiario /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/agenda/terapeutas' element={<AgendaTerapeutas />} />,
    <Route path='/dashboard/administrador/agenda/listadeespera' element={<HeaderPadrao><ListaEspera /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/agenda/areacompartilhada' element={<AgendaAreaCompartilhada />} />,
    <Route path='/dashboard/administrador/guias' element={<HeaderPadrao><Guias /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/configuracoes/documentos' element={<Documentos />} />,
    <Route path='/documentos-pendentes/administrador/:id' element={<HeaderPadrao><DocumentoAssinatura /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/terapeutas' element={<HeaderPadrao><ListagemTerapeutas /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/terapeutas/glosas' element={<HeaderPadrao><ListagemDeGlosas /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/terapeutas/novo' element={<HeaderPadrao><CadastroPerfilTerapeuta /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/terapeuta/perfil/:id' element={<HeaderPadrao><CadastroPerfilTerapeuta /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/terapeuta/agenda/:id' element={<AgendaTerapeutaAdmin />} />,
    <Route path='/dashboard/administrador/pacientes' element={<HeaderPadrao><ListagemPacientes /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/novo' element={<HeaderPadrao><PerfilPacienteAdmin /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/novo/:lista_id' element={<HeaderPadrao><PerfilPacienteAdmin /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/perfil/:id' element={<HeaderPadrao><PerfilPacienteAdmin /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/prontuarios' element={<HeaderPadrao><Prontuarios /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/prontuarios/:id' element={<HeaderPadrao><Prontuarios /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/pacientes/pesquisar' element={<HeaderPadrao><Pesquisar /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/atendimentos' element={<Atendimentos />} />,
    <Route path='/dashboard/administrador/funcionarios' element={<HeaderPadrao><Funcionarios /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/relatorios/' element={<HeaderPadrao><RelatóriosEstrategicos /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/relatorios/graficos' element={<HeaderPadrao><GraficosPacientes /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/relatorios/atendimentos' element={<HeaderPadrao><RelatoriosAtendimentos /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/planodesaude' element={<HeaderPadrao><PlanoDeSaudeAdmin /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/configuracoes' element={<ConfiguracoesAdmin />} />,
    <Route path='/dashboard/administrador/configuracoes/grupopermissoes' element={<GrupoPermissao />} />,
    <Route path='/dashboard/administrador/configuracoes/tipoatendimento' element={<TiposAtendimento />} />,
    <Route path='/dashboard/administrador/configuracoes/tipodemanda' element={<TipoDemandas />} />,
    <Route path='/dashboard/administrador/configuracoes/terapias' element={<Terapias />} />,
    <Route path='/dashboard/administrador/configuracoes/especialidades' element={<Especialidade />} />,
    <Route path='/dashboard/administrador/configuracoes/modalidades' element={<Modalidade />} />,
    <Route path='/dashboard/administrador/configuracoes/salas' element={<Sala />} />,
    <Route path='/dashboard/administrador/configuracoes/estacoes' element={<Estacao />} />,
    <Route path='/dashboard/administrador/configuracoes/areacompartilhada' element={<HeaderPadrao><AreaCompartilhada /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/configuracoes/tiposavaliacao' element={<TiposAvaliacao />} />,
    <Route path='/dashboard/administrador/configuracoes/Status' element={<Status />} />,
    <Route path='/dashboard/administrador/configuracoes/diagnosticos' element={<Diagnosticos />} />,
    <Route path='/dashboard/administrador/configuracoes/horarios-atendimento' element={<HeaderPadrao><HorariosAtendimento /></HeaderPadrao>} />,
    <Route path='/dashboard/administrador/gerador-de-documento/:id' element={<GeradorDeDocumento />} />,
];

export default AdministradorRoutes;
