//#region IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../context/auth';
import { Container, Modal } from './styles';
import moment from 'moment';
import api from '../../../../services/api';
import { useAtendimentoReposicao } from '../../../../context/atendimentoReposicao';
import ReposicaoModal from '../../../../components/Modais/ReposicaoModal';
import ModalTitulo from '../../../../components/ModalTitulo';
import DiaHoraService from '../../../../services/DiaHoraService';
import { Form } from '@unform/web';
import { ClipLoader, ScaleLoader } from 'react-spinners';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { CgClose } from 'react-icons/cg';
import Button from '../../../../components/Button';
import { BsArrowLeft } from 'react-icons/bs';
import TextArea from '../../../../components/TextArea';
import Swal from 'sweetalert2';
import PacienteService from 'services/PacienteService';
//#endregion

const AtendimentoModal = ({ atualizaSocket = () => { }, isAtendimentoEditavel, tipoAtendimentoOptions, statusAtendimento, modalidadesOptions, horarioAtendimentoOptions, atendimentos, pacientes, estacaoOptions, pacienteOptionsOriginal, atendimentoId, controleDiarioId, onClose = () => { }, setHouveAlteracaoStatusEdicao = () => { }, snackbar }) => {
    //#region VARIÁVEIS*/
    /*==UTILITÁRIOS==*/
    const Usuario = useAuth();
    const formRef = useRef(null);
    const modalId = 'modalDetalhesDoAtendimento';
    const [loading, setloading] = useState(false);
    const [salvando, setsalvando] = useState(false);
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
    const [hour, setHour] = useState(null);
    const [pacienteService, setPacienteService] = useState(null);
    //===MEMORIA===\\
    const [comentario, setComentario] = useState('');
    const [atendimento, setAtendimento] = useState(null);
    const [alteracao, setAlteracao] = useState(null);
    const [alteracaoId, setAlteracaoId] = useState(null);
    const [alterado, setAlterado] = useState(false);
    const { atendimentoReposicao, setAtendimentoReposicaoState } = useAtendimentoReposicao();
    const [isModalReposicaoOpen, setIsModalReposicaoOpen] = useState(false);

    /*==EDICAO==*/
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [pacienteOptSelecionado, setPacienteOptSelecionado] = useState([]);
    const [pacienteOptions, setPacienteOptions] = useState([]);

    const [terapiasPacienteOptions, setTerapiasPaciente] = useState([]);
    const [terapiasPacienteOptSelecionado, setTerapiasPacienteOptSelecionado] = useState([]);
    const [terapiasPacienteSelecionado, setTerapiasPacienteSelecionado] = useState([]);

    const [terapeutasOptions, setTerapeutasOptions] = useState([]);
    const [terapeutaOptSelecionado, setTerapeutaOptSelecionado] = useState([]);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);

    const [modalidadeOptSelecionado, setModalidadeOptSelecionado] = useState([]);
    const [modalidadeSelecionado, setModalidadesSelecionada] = useState(null);

    const [tipoAtendimentoOptSelecionado, setTipoAtendimentoOptSelecionado] = useState([]);
    const [tipoAgendamentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);

    const [reservaSelecionado, setReservaSelecionada] = useState(null);

    const [estacaoOptSelecionado, setEstacaoOptSelecionado] = useState();
    const [estacaoSelecionado, setEstacaoSelecionado] = useState();

    const [statusAtendimentoAtualOptSelecionado, setStatusAtendimentoAtualOptSelecionado] = useState([]);
    const [statusAtendimentoOriginalOptSelecionado, setStatusAtendimentoOriginalOptSelecionado] = useState([]);

    const [horarioSelecionadoOpt, setHorarioSelecionadoOpt] = useState(null);
    //#endregion

    //#region FUNÇÕES
    /*==CHECKERS==*/
    /**
     * Função que verifica se o terapeuta está disponível para atendimento.
     * @param {Number} terapeuta_id Identificador do terapeuta
     * @param {String} horario Hora usada para chegar os agendamentos do terapeuta
     * @returns {boolean}
     */
    const checkAtendimentosTerapeuta = (terapeuta_id, horario, agendamentoId = null) => {
        let isTerapeuta = false;
        atendimentos.forEach((agendamento) => {
            if (agendamento.terapeuta_id === terapeuta_id && agendamento.data_atendimento_inicial === horario._i && agendamento.id !== agendamentoId) isTerapeuta = true;
        });
        return isTerapeuta;
    };
    /*==GETTERS==*/
    /**
     * Função que carreta as terapias e especialidades para a listagem
     * @param {Array} paciente Todas as informações do paciente.
     * @returns {void}
     */
    const getTerapiasEspecialidades = (paciente) => {
        if (paciente !== null) {
            let terapiasList = [];
            let especialidadesList = [];
            if (paciente.terapia_paciente !== undefined && paciente.terapia_paciente.length > 0) {
                paciente.terapia_paciente.forEach((terapia) => {
                    terapiasList.push({ value: terapia.terapia_id, label: terapia.terapia, plano_saude_id: terapia.plano_saude_id });
                    if (especialidadesList.find((value) => value.value === terapia.especialidade_id) === undefined) {
                        especialidadesList.push({ value: terapia.especialidade_id, label: terapia.especialidade });
                    }
                })
            }
        } else {
            snackbar.displayMessage('Selecione o paciente.', 'warning');
        }
    }
    const getAtendimento = async () => {
        const apiUrl = `api/atendimento/getByControleDiario/`;
        const listasUrl = `?listas=solicitacao_de_alteracaos,guia,paciente.terapias_paciente`;
        const extraSelectUrl = `&${[
            'extraSelect=terapia_paciente.plano_saude.id',
            'terapia_paciente.paciente.nome',
            'terapia_paciente.terapia.terapia',
            'terapia_paciente.especialidade.especialidade',
            'terapia_paciente.plano_saude.nome_reduzido',
        ].join(',')}`;
        const response = await api.get(apiUrl + controleDiarioId + listasUrl + extraSelectUrl + '&atendimento_id=' + atendimentoId);
        let atendimentoResponse = response.data[0];
        atendimentoResponse.data = atendimentoResponse.data_atendimento_inicial.split('T')[0];
        atendimentoResponse.horario = moment(atendimentoResponse.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
        atendimentoResponse.data_atendimento_numero = DiaHoraService.getDiaMesAnoFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
        atendimentoResponse.data_atendimento_extenso = DiaHoraService.getDiaSemanaFromFormatoDatabase(atendimentoResponse.data_atendimento_inicial);
        if (atendimentoResponse.atendimento_alteracaos) {
            setStatusAtendimentoOriginalOptSelecionado(statusAtendimento.filter(filter => filter.value === atendimentoResponse.atendimento_alteracaos.alteracao_sugestao_recepcao));
        }
        const newAlterado = atendimentoResponse.alteracao_id ? true : false;
        setAlteracaoId(atendimentoResponse.alteracao_id);
        setAlterado(newAlterado);
        if (newAlterado) {
            setComentario(atendimentoResponse.comentario_a);
        }
        setAtendimento(atendimentoResponse.old ? atendimentoResponse.old : atendimentoResponse);
        return await atendimentoResponse;
    };
    const getTerapeutas = (especialidade_id, terapia_id, date, hour, horarios_atendimento_id) => {
        if (especialidade_id !== null && terapia_id !== null) {
            let link = `api/terapeuta/disponivel/${date}/${hour}?atendimento=true&terapia_id=${terapia_id}&especialidade_id=${especialidade_id}&horarios_atendimento_id=${horarios_atendimento_id}&agendamento_id=${atendimentoId}`;
            return api.get(link).then(({ data }) => {
                let terapeutaOptions = [];
                for (let index = 0; index < data.length; index++) {
                    const terapeuta = data[index];
                    terapeutaOptions.push({ value: terapeuta.id, label: terapeuta.nome, pos: !(terapeuta.nome.includes('(') && terapeuta.nome.includes(')')) ? 0 : 1 });
                }
                if (!terapeutaOptions) {
                    snackbar.displayMessage('Não há terapeutas disponíveis para essa Especialidade e Terapia.', 'warning');
                }
                let t1 = terapeutaOptions.filter((filter) => filter.pos === 0);
                let t2 = terapeutaOptions.filter((filter) => filter.pos === 1);
                t1 = t1.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                t2 = t2.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
                let mergedObj = [];
                t1.forEach((e) => mergedObj.push(e));
                t2.forEach((e) => mergedObj.push(e));
                setTerapeutasOptions(mergedObj);
                return mergedObj;
            });
        }
    };
    const carregaTudo = async () => {
        try {
            let modalidadeOptionsLocal = modalidadesOptions;
            const estacaoOptionsLocal = estacaoOptions;
            getAtendimento().then(async (response) => {
                const newResponse = response;
                const pacienteData = {
                    terapia_pacientes: newResponse.terapias_paciente
                };
                const especialidade_id = newResponse.especialidade_id;
                const terapia_id = newResponse.terapia_id;
                const horarios_atendimento_id = newResponse.horarios_atendimento_id;
                const terapia_paciente_id = newResponse.terapia_paciente_id;
                const paciente_id = newResponse.paciente_id;
                const terapeuta_id = newResponse.terapeuta_id;
                const reserva = newResponse.reserva;
                const tipo_atendimento_id = newResponse.tipo_atendimento_id;
                const modalidade_id = newResponse.modalidade_id;
                const estacao_id = newResponse.estacao_id;
                const status_atendimento = newResponse.sugestao_recepcao;
                setStatusAtendimentoAtualOptSelecionado(statusAtendimento.filter(filter => filter.value === status_atendimento));

                await getTerapiasEspecialidades(pacienteData);
                const terapeutaOptions = await getTerapeutas(
                    especialidade_id,
                    terapia_id,
                    moment(newResponse.created_at).format('YYYY-MM-DD'),
                    `${newResponse.hora_atendimento.split(':')[0]}:${newResponse.hora_atendimento.split(':')[1]}`,
                    horarios_atendimento_id
                );
                const selectTerapia = pacienteData.terapia_pacientes.map(el => ({
                    value: el.id,
                    label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                    terapia_id: el.terapia_id,
                    especialidade_id: el.especialidade_id,
                    plano_saude_id: el.plano_saude_id,
                }));
                setTerapiasPacienteSelecionado(terapia_paciente_id);
                setTerapiasPacienteOptSelecionado(selectTerapia.filter((e) => Number(e.value) === Number(terapia_paciente_id)));

                setPacienteSelecionado(paciente_id);
                setPacienteOptSelecionado(pacienteOptions.filter((e) => Number(e.value) === Number(paciente_id)));

                setTerapeutaSelecionado(terapeuta_id);
                setTerapeutaOptSelecionado(terapeutaOptions.filter(e => Number(e.value) === Number(terapeuta_id)));

                setReservaSelecionada(reserva);

                setTipoAtendimentoSelecionado(tipo_atendimento_id);
                setTipoAtendimentoOptSelecionado(tipoAtendimentoOptions.find((value) => value.value === tipo_atendimento_id));

                setModalidadesSelecionada(modalidade_id);
                setModalidadeOptSelecionado(modalidadeOptionsLocal.find((value) => value.value === modalidade_id));

                setEstacaoSelecionado(estacao_id);
                setEstacaoOptSelecionado(estacaoOptionsLocal.filter((e) => e.value === estacao_id));
                const horario_atendimento = horarioAtendimentoOptions.filter((e) => Number(e.id) === Number(horarios_atendimento_id));
                setHorarioSelecionadoOpt(horario_atendimento);

                setAlteracao(response);
            });
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    }
    //#endregion

    //#region HANDLES
    const handleCloseCheck = () => {
        Swal.fire({
            title: 'Deseja mesmo fechar?',
            text: 'Se você fechar dessa maneira, não irá salvar nenhuma infomação. Deseja mesmo fechar?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonAriaLabel: 'Sair sem salvar.',
            cancelButtonAriaLabel: 'Não Sair!'
        }).then((response) => {
            if (response.isConfirmed) {
                onClose();
            }
        })
    }
    const handleOutsideClick = (e) => {
        if (e.target.id === modalId) handleCloseCheck();
    };
    const handleChangePaciente = (e) => {
        const pacienteData = pacientes.find((value) => Number(value.id) === Number((e.value === undefined) ? e.target.value : e.value));

        getTerapiasEspecialidades(pacienteData);
        let terapiaPacientesList = [];
        if (pacienteData.terapia_paciente !== undefined) {
            for (let index = 0; index < pacienteData.terapia_paciente.length; index++) {
                const el = pacienteData.terapia_paciente[index];
                terapiaPacientesList.push({
                    value: el.id,
                    label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                    terapia_id: el.terapia_id,
                    especialidade_id: el.especialidade_id,
                })
            }
        }
        setTerapiasPaciente(terapiaPacientesList);
        setTerapiasPacienteSelecionado(null);
        setTerapiasPacienteOptSelecionado(null);

        setTerapeutaSelecionado(null);
        setTerapeutaOptSelecionado(null);

        setPacienteSelecionado((e.value === undefined) ? e.target.value : e.value);
        setPacienteOptSelecionado(pacienteData);
    };
    const handleChangeHorario = (e) => {
        setHorarioSelecionadoOpt((e.target !== undefined) ? e.target : e);
        document.getElementById('hora_final').value = (e.target !== undefined)
            ? e.target.hora_final
            : e.hora_final;
    }
    const handleChangeTerapiaPacientes = async (e) => {
        getTerapeutas(e.especialidade_id, e.terapia_id, moment(atendimento.created_at).format('YYYY-MM-DD'), moment(atendimento.data_atendimento_inicial).format('HH:mm'), atendimento.horarios_atendimento_id);
        setTerapiasPacienteSelecionado(e.value);
        setTerapiasPacienteOptSelecionado(e);
    }
    const handleChangeTerapeuta = (e) => {
        setTerapeutaSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTerapeutaOptSelecionado(e);
    };
    const handleChangeModalidades = (e) => {
        setModalidadesSelecionada((e.value === undefined) ? e.target.value : e.value);
        setModalidadeOptSelecionado(e);
    };
    const handleChangeTipoAtendimento = (e) => {
        setTipoAtendimentoSelecionado((e.value === undefined) ? e.target.value : e.value);
        setTipoAtendimentoOptSelecionado(e);
    };
    const handleChangeEstacao = (e) => {
        setEstacaoSelecionado((e.value === undefined) ? e.target.value : e.value);
        setEstacaoOptSelecionado(e);
    }
    const handleChangeStatusRecepcao = (e, tipo) => {
        if (tipo === 'atual') {
            setStatusAtendimentoAtualOptSelecionado(e);
        } else if (tipo === 'original') {
            setStatusAtendimentoOriginalOptSelecionado(e)
        }
    }
    const handleChangeComentario = (e) => {
        setComentario(e.target.value);
    };

    const handleSubmit = ({ hora_final }) => {
        const fecha = () => {
            atualizaSocket();
            onClose();
        }
        setsalvando(true);
        try {
            if (statusAtendimentoOriginalOptSelecionado === null || statusAtendimentoOriginalOptSelecionado?.value === undefined || statusAtendimentoOriginalOptSelecionado?.value === null) {
                Swal.fire('Faltando status!', 'Falta adicionar o status do atendimento original. Retorne e adicione o status do atendimento original.', 'error');
                setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                return;
            }
            Swal.fire({
                title: 'Deseja mesmo alterar esse atendimento?',
                text: 'Esta ação resultara em impactos em todo o sistema, tenha certeza do que esta fazendo!',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonAriaLabel: 'Confirmar alteração!',
                cancelButtonAriaLabel: 'Não anterar atendimento'
            }).then((response) => {
                if (response.isConfirmed) {
                    let horarioLocal = horarioSelecionadoOpt[0] ? horarioSelecionadoOpt[0] : horarioSelecionadoOpt;
                    let newData = {
                        atendimento_id: atendimento.id,
                        paciente_id: pacienteSelecionado,
                        terapia_paciente_id: terapiasPacienteSelecionado,
                        terapia_id: terapiasPacienteOptSelecionado && terapiasPacienteOptSelecionado[0] ? terapiasPacienteOptSelecionado[0].terapia_id : terapiasPacienteOptSelecionado.terapia_id,
                        especialidade_id: terapiasPacienteOptSelecionado && terapiasPacienteOptSelecionado[0] ? terapiasPacienteOptSelecionado[0].especialidade_id : terapiasPacienteOptSelecionado.especialidade_id,
                        terapeuta_id: terapeutaSelecionado,
                        modalidade_id: modalidadeSelecionado,
                        tipo_atendimento_id: tipoAgendamentoSelecionado,
                        reserva: reservaSelecionado === 'sim' ? true : false,
                        horarios_atendimento_id: horarioLocal.id,
                        data_atendimento_inicial: moment(atendimento.created_at).format('YYYY-MM-DD') + " " + horarioLocal.label,
                        data_atendimento_final: moment(atendimento.created_at).format('YYYY-MM-DD') + " " + hora_final,
                        estacao_id: estacaoSelecionado,
                        status: '4',/* APROVADO */
                        sugestao_recepcao: statusAtendimentoOriginalOptSelecionado.value,
                        comentario: comentario,
                        user_id_solicitante: atendimento.terapeuta_user_id ? atendimento.terapeuta_user_id : Usuario.user.id,
                        user_id_analista: Usuario.user.id,
                    }
                    if (isAtendimentoEditavel) {
                        api.post(`api/atendimento-alteracao`, newData).then(() => {
                            if (statusAtendimentoAtualOptSelecionado) {
                                api.put(`api/atendimento/${atendimento.id}`, { status: statusAtendimentoAtualOptSelecionado.value }).then(() => {
                                    fecha();
                                    snackbar.displayMessage("Alteração do atendimento cadastrada com sucesso!", "success");
                                }).catch((e) => {
                                    console.error(e);
                                    setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                                    snackbar.displayMessage("Houve um problema ao alterar o status do atendimento!", "error");
                                });
                            } else {
                                fecha();
                                snackbar.displayMessage("Alteração do atendimento cadastrada com sucesso!", "success");
                            }
                        }).catch((e) => {
                            console.error(e);
                            setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                            snackbar.displayMessage("Houve um problema ao adicionar a alteração do atendimento!", "error");
                        });
                    } else {
                        setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
                        snackbar.displayMessage("Não é permitido editar esse atendimento!", "error");
                    }
                } else {
                    setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido se o usuário cancelar
                }
            });
        } catch (e) {
            console.error(e);
            setsalvando(false); // Adicionado aqui para garantir que o estado seja revertido em caso de erro
            snackbar.displayMessage("Houve um problema ao alterar o atendimento!", "error");
        }
    };
    const handleReprovaAlteracao = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: 'Deseja realmente reprovar a alteração do atendimento? Apenas o comentário será salvo nessa alteração!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
        }).then((result) => {
            const putObj = { status: 3, comentario: comentario };
            if (result.isConfirmed) {
                api.put(`api/atendimento-alteracao/${id}`, putObj).then(() => {
                    snackbar.displayMessage("Alteração do atendimento reprovada com sucesso!", "success");
                    onClose();
                }).catch((e) => {
                    console.error(e);
                    snackbar.displayMessage("Houve um problema ao reprovar a alteração do atendimento!", "error");
                });
            }
        });
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {/* ON LOAD */
        setloading(true);
        carregaTudo();
    }, []);
    useEffect(() => {
        if (pacienteOptionsOriginal && hour) {
            const newPacienteOptions = PacienteService.adicionaStatusIndisponivel(pacienteOptionsOriginal, hour, atendimentoId, atendimentos);
            setPacienteOptions(newPacienteOptions);
        }
    }, [pacienteOptionsOriginal, hour]);
    useEffect(() => {
        if (pacienteOptions) {
            setPacienteService(new PacienteService(pacienteOptions));
        }
    }, [pacienteOptions]);
    useEffect(() => {
        if (atendimentoReposicao === null) return;
        setIsModalReposicaoOpen(true);
    }, [atendimentoReposicao]);
    useEffect(() => {
        if (!atendimento) return;
        setHour(atendimento.hora_atendimento);
    }, [atendimento]);
    useEffect(() => {
        if (terapeutaSelecionado && terapeutasOptions.length > 0) {
            setTerapeutaOptSelecionado(terapeutasOptions.find((value) => value.value === terapeutaSelecionado));
        }
    }, [terapeutaSelecionado]);
    useEffect(() => {
        if (pacienteSelecionado) {
            setPacienteOptSelecionado(pacienteOptions.find((value) => value.value === pacienteSelecionado));
        }
    }, [pacienteSelecionado]);
    //#endregion

    //#region HTML
    return (
        <>
            <Modal id={modalId} onClick={handleOutsideClick}>
                <Container>
                    <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                        <div className="container-fluid d-flex align-items-center justify-content-between">
                            {isAtendimentoEditavel ?
                                <Button className='btn-padrao btn-reposicao w-200px' onClick={() => setAtendimentoReposicaoState(atendimento.id)}>
                                    Efetuar Reposição
                                </Button>
                                : <div className='w-200px'></div>
                            }
                            <ModalTitulo>{isAtendimentoEditavel ? 'Alterar Atendimento' : 'Visualizar Alterações'}</ModalTitulo>
                            <label className='btn-outline w-200px text-right' onClick={() => handleCloseCheck()}>
                                <CgClose size={22} color='red' />
                            </label>
                        </div>
                    </nav>
                    <br></br>
                    {(!loading && atendimento)
                        ? <Form className='row' onSubmit={handleSubmit} ref={formRef}>
                            {/* VISUALIZAÇÃO DADOS ORIGINAIS */}
                            <div className={'col-6'}>
                                <div className='div-titulo'>
                                    <label className='font-16px'>Dados Originais do Atendimento</label>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Input disabled title='Nome:' name='_paciente' value={atendimento && atendimento.paciente_nome} />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Data:' name='_data'
                                            defaultValue={
                                                atendimento && dayName[atendimento.dia_semana]
                                            } />
                                    </div>
                                    <div className='col-6'>
                                        <Input disabled title='Horario:' name='_horario'
                                            defaultValue={
                                                atendimento && moment.utc(atendimento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(atendimento.data_atendimento_final).format('HH:mm')
                                            } />
                                    </div>

                                    <div className='col-12'>
                                        <Input disabled title='Terapia do Paciente:' name='_terapia_paciente'
                                            defaultValue={
                                                atendimento && atendimento.terapia + ' - ' + atendimento.especialidade + ' - ' + atendimento.nome_reduzido
                                            } />
                                    </div>
                                    <div className='col-12'>
                                        <Input disabled title='Terapeuta:' name='_terapeuta' defaultValue={
                                            atendimento && atendimento.terapeuta_nome
                                        } />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Modalidade:' name='_Modalidade' defaultValue={
                                            atendimento && atendimento.modalidade
                                        } />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Tipo de Atendimento:' name='_tipoAtendimento' defaultValue={
                                            atendimento && atendimento.tipo_atendimento
                                        } />
                                    </div>
                                    <div className='col-4'>
                                        <Input disabled title='Sala / Estação:' name='_estacao' defaultValue={
                                            atendimento && (atendimento.sala + ' / ' + atendimento.estacao)
                                        } />
                                    </div>

                                    <div className='col-12'>
                                        <Select
                                            id={`status-original`}
                                            name={`_status-original`}
                                            label='Status do Atendimento Original:'
                                            options={statusAtendimento}
                                            readOnly={isAtendimentoEditavel ? '' : 'readOnly'}
                                            value={statusAtendimentoOriginalOptSelecionado}
                                            onChange={(e) => { handleChangeStatusRecepcao(e, 'original'); }}
                                        />
                                    </div>

                                    <div className='col-12'>
                                        <TextArea disabled title='Comentário:' name='_comentario' defaultValue={atendimento && atendimento.comentario} />
                                    </div>
                                    <div className='d-flex justify-content-start col-12 mt-2'>
                                        <Button type='button' onClick={() => onClose()} disabled={salvando}> <BsArrowLeft size={25} className='me-2' /> Voltar </Button>
                                    </div>
                                </div>
                            </div>
                            {/* EDIÇÃO/VISUALIZAÇÃO DA ALTERAÇÃO */}
                            {(atendimento && !atendimento.reposicao) &&
                                <div className='col-6'>
                                    <div className='div-titulo'>
                                        <label className='font-16px'>{isAtendimentoEditavel ? 'Alterar Atendimento' : 'Alteraração do Atendimento'}</label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Select label='Paciente:' id='paciente_select' name='paciente' options={pacienteOptions} value={pacienteOptSelecionado} filterOption={pacienteService.customPacienteFilter} defaultValue={pacienteOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangePaciente(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Input disabled={!isAtendimentoEditavel} title='Data:' name='data' readOnly={isAtendimentoEditavel ? '' : 'readOnly'} defaultValue={alteracao && dayName[alteracao.dia_semana]} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='horario' label='Horário:' options={horarioAtendimentoOptions} onChange={(e) => handleChangeHorario(e)} defaultValue={horarioSelecionadoOpt} value={horarioSelecionadoOpt} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} />
                                        </div>
                                        <div className='col-4'>
                                            <Input id='hora_final' name='hora_final' title={'Horário final:'} defaultValue={alteracao?.data_atendimento_final && moment.utc(alteracao.data_atendimento_final).format('HH:mm')} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} />
                                        </div>
                                        <div className='col-12'>
                                            <Select label='Terapia do Paciente:' name='terapia_pacientes' options={terapiasPacienteOptions} value={terapiasPacienteOptSelecionado} defaultValue={terapiasPacienteOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangeTerapiaPacientes(e)} />
                                        </div>
                                        <div className='col-12'>
                                            <div className='col-lg-12 col-sm-12'>
                                                <Select label='Terapeuta:' name='Terapeuta' options={terapeutasOptions} value={terapeutaOptSelecionado} defaultValue={terapeutaOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangeTerapeuta(e)} />
                                            </div>
                                        </div>
                                        <div className='col-4'>
                                            <Select name='modalidade_id' label='Modalidade:' options={modalidadesOptions} value={modalidadeOptSelecionado} defaultValue={modalidadeOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangeModalidades(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='tipo_atendimento_id' label='Tipo de Atendimento:' options={tipoAtendimentoOptions} defaultValue={tipoAtendimentoOptSelecionado} value={tipoAtendimentoOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangeTipoAtendimento(e)} />
                                        </div>
                                        <div className='col-4'>
                                            <Select name='estacao_id' label='Sala / Estação:' options={estacaoOptions} value={estacaoOptSelecionado} defaultValue={estacaoOptSelecionado} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} onChange={(e) => handleChangeEstacao(e)} />
                                        </div>
                                        <div className='col-12'>
                                            <Select id={`status-atual`} name={`status-atual`} label='Status Atual do Atendimento:' options={statusAtendimento} readOnly={isAtendimentoEditavel ? '' : 'readOnly'} value={statusAtendimentoAtualOptSelecionado} onChange={(e) => { handleChangeStatusRecepcao(e, 'atual'); }} />
                                        </div>
                                        <div className='col-12'>
                                            <TextArea readOnly={isAtendimentoEditavel ? '' : 'readOnly'} title='Comentário:' name='comentario' value={comentario} onChange={(e) => handleChangeComentario(e)} />
                                        </div>

                                        {alterado &&
                                            <div className='col-12 d-flex justify-content-end mt-2'>
                                                <Button type={'button'} className='btn-padrao btn-vermelho' onClick={() => handleReprovaAlteracao(alteracaoId)}> Reprovar Alteração </Button>
                                            </div>
                                        }
                                        {isAtendimentoEditavel &&
                                            <div className='col-12 d-flex justify-content-end mt-2'>
                                                <Button type={'submit'} disabled={salvando}>
                                                    {salvando
                                                        ? <>
                                                            Salvando <ScaleLoader css={`display: block; margin-top: 0px; margin-left: 4px; border-color: orange;`} height={20} color={"#fd931a"} />
                                                        </>
                                                        : 'Alterar Atendimento'
                                                    }
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </Form>
                        : <div className='m-auto'>
                            <ClipLoader size={50} color='#4906AB' />
                        </div>
                    }
                </Container>
            </Modal>
            {isModalReposicaoOpen && <ReposicaoModal setHouveAlteracaoStatusEdicao={setHouveAlteracaoStatusEdicao} atendimentoId={atendimentoId} onClose={() => setIsModalReposicaoOpen(false)} />}
        </>
    );
    //#endregion
};
export default AtendimentoModal;
