import React, { useState, useEffect, useRef } from "react";
import { FilterStyle } from "./styles";
import api from "../../services/api";
import { AiFillCaretDown } from 'react-icons/ai';

export default function FiltroTerapias({ resultRef, callBack, sourceStatus, setTerapeutas, source }) {
    //#region VARIAVEIS
    const [terapias, setTerapias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [terapiaSelecionada, setTerapiaSelecionada] = useState([]);
    const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [expand, setExpand] = useState(false);
    const filtroDivRef = useRef();
    //#endregion
    //#region HANDLES
    const handleFilterStatus = (status, checked) => {
        if (checked) {
            setStatusSelecionado(prev => [...prev, status]);
        } else {
            setStatusSelecionado(prev => prev.filter(s => s !== status));
        }
    };
    const handleSelectFilter = (setFilterState, value, checked) => {
        if (checked) {
            setFilterState(prev => [...prev, value]);
        } else {
            setFilterState(prev => prev.filter(item => item !== value));
        }
    };
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpand(false);
        }
    }
    //#endregion
    //#region GETTERS
    const getTerapias = () => {
        api.get(`api/terapia`).then(({ data }) => {
            setTerapias(data);
        });
    };

    const getEspecialidades = () => {
        api.get(`api/especialidade`).then(({ data }) => {
            setEspecialidades(data);
        });
    };
    //#endregion
    //#region FUNCOES
    const filtrar = () => {
        const filtered = source.filter(e => {
            const matchesTerapia = terapiaSelecionada.length === 0 || e?.terapeuta_terapia?.some(t => terapiaSelecionada.includes(String(t.terapia_id)));
            const matchesEspecialidade = especialidadeSelecionada.length === 0 || e?.terapeuta_especialidades?.some(esp => especialidadeSelecionada.includes(String(esp.especialidade_id)));
            const matchesStatus = statusSelecionado.length === 0 || statusSelecionado.includes(e.status);

            return matchesTerapia && matchesEspecialidade && matchesStatus;
        });

        setTerapeutas(filtered);
    };

    const toggleExpand = () => {
        setExpand(prev => !prev);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        document.addEventListener('mousedown', (event) => {
            if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                setExpand(false);
            }
        });
        getTerapias();
        getEspecialidades();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        filtrar();
    }, [terapiaSelecionada, especialidadeSelecionada, statusSelecionado]);
    //#endregion
    //#region HTML
    return (
        <div style={{ width: "100%" }}>
            <div style={FilterStyle} className="w-100" ref={filtroDivRef}>
                <div className="d-flex row" onClick={toggleExpand}>
                    <div className="d-flex col-11">
                        <p id="blank" style={{ marginBottom: -30 }}>
                            {expand ? 'Fechar ' : 'Expandir '} Filtro
                        </p>
                    </div>
                    <div className="col-1">
                        <AiFillCaretDown className="btn-icone" color="white" size={25} />
                    </div>
                </div>
                {expand && (
                    <div style={{ marginTop: 30, marginLeft: -10 }}>
                        <div>
                            <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Selecionar Terapias:</label>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {terapias.map((terapia) => (
                                    <div key={terapia.id} className="d-inline-flex flex-nowrap">
                                        <input
                                            id={'ter' + terapia.terapia}
                                            type="checkbox"
                                            name="terapia[]"
                                            value={terapia.id}
                                            onChange={(e) => handleSelectFilter(setTerapiaSelecionada, e.target.value, e.target.checked)}
                                        />
                                        <label htmlFor={'ter' + terapia.terapia} style={{ margin: 5 }}>
                                            {terapia.terapia}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Selecionar Especialidades:</label>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {especialidades.map((especialidade) => (
                                    <div key={especialidade.id} className="d-inline-flex flex-nowrap">
                                        <input
                                            id={'esp' + especialidade.especialidade}
                                            type="checkbox"
                                            name="especialidade[]"
                                            value={especialidade.id}
                                            onChange={(e) => handleSelectFilter(setEspecialidadeSelecionada, e.target.value, e.target.checked)}
                                        />
                                        <label htmlFor={'esp' + especialidade.especialidade} style={{ margin: 5 }}>
                                            {especialidade.especialidade}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Filtrar por Status:</label>
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {['Ativo', 'Inativo', 'Bloqueado', 'Pendente'].map((status) => (
                                    <div key={status} className="d-flex flex-nowrap">
                                        <input
                                            id={'status_' + status}
                                            type="checkbox"
                                            value={status}
                                            onChange={(e) => handleFilterStatus(status, e.target.checked)}
                                        />
                                        <label htmlFor={'status_' + status} style={{ margin: 5 }}>
                                            {status}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
    //#endregion
}