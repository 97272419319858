import { useState, useEffect } from "react";

import { Container, ContainerSub, Header } from './styles';
import api from "../../services/api";
import { useAuth } from "../../context/auth";
import HeaderPadrao from "../../layouts/HeaderPadrao";
import RecadosCard from "../DashboardPadrao/RecadosCard";
import { GridHeader } from "./styles";
import { GridRecados } from "./styles";
import { MostradoresColoridos } from "../../components/MostradoresColoridos";
import moment from "moment";
import AcessoRapido from "../../components/AcessoRapido";
import { SelectLista, VariasListas } from "../../services/ListasServices";
import { TutorialBadge } from "components/AjudanteInicial";
import { Dashboard } from "helpers/dashboard.helper";
import { ScaleLoader } from "react-spinners";

export default function DashboardPadrao() {
    //#region VARIAVEIS
    const { user } = useAuth();
    const estacaoList = new SelectLista('estacao', 'id,estacao');
    const salaList = new SelectLista('sala', 'id,sala');
    const terapiaList = new SelectLista('terapia', 'id,terapia');
    const tipo_user = user.tipo_user;

    const [dashboardStructure, setDashboardStructure] = useState(null);
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };
    //#endregion

    //#region FUNCOES

    const onLoad = async () => {
        const tipoUserUpper = tipo_user.toUpperCase();
        const dashboard = new Dashboard(tipoUserUpper, user.id);
        await dashboard.setMostradoresInformacao();

        try {
            if (dashboard.getEnableRecados()) {
                const recadoResponse = await api.get('api/recado/get/by/group/' + user.tipo_user);
                setRecado(recadoResponse.data);
            }
            if (dashboard.getEnableMostradores()) {
                const mostradores = dashboard.getMostradores();
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradores
                });
            }
            if (dashboard.getEnableAcessoRapido) {
                const acessoRapido = dashboard.getAcessoRapido();
                setAcessoRapido(acessoRapido);
            }
            setDashboardStructure(dashboard);
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
        }
    }, [tipo_user]);
    useEffect(() => {
        if (false === true) {
            const fetchData = async () => {
                try {
                    const todasListas = await VariasListas.getTodasListas([
                        estacaoList,
                        salaList,
                        terapiaList
                    ]);
                } catch (error) {
                    console.error('Erro ao buscar listas:', error);
                }
            };

            fetchData();
        }
    }, []);
    //#endregion
    /*#region TUTORIAL */
    //#endregion
    //#region HTML
    return (
        <>
            <HeaderPadrao>
                <Container className='row'>
                    <ContainerSub id='containerSub'>
                        {!dashboardStructure &&
                            <label className="m-auto text-center">
                                Carregando dashboard...<br></br><br></br>
                                <ScaleLoader color='#000' loading={true} />
                            </label>
                        }
                        {dashboardStructure && dashboardStructure.getEnableHeader() &&
                            <GridHeader id="header">
                                <Header>
                                    <label>Bem Vindo {user?.name && user?.name}!</label>
                                    <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                                </Header>
                            </GridHeader>
                        }
                        {(dashboardStructure && dashboardStructure && dashboardStructure?.getEnableMostradores()) &&
                            <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores || mostradoresColoridos.mostradores.length === 0} />
                        }
                        {dashboardStructure?.getEnableRecados() && <GridRecados id="recados">
                            <RecadosCard title={"Recado"} recado={recado} />
                        </GridRecados>}
                        {acessoRapido && dashboardStructure && dashboardStructure?.getEnableAcessoRapido() &&
                            <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                        }
                    </ContainerSub>
                </Container>
            </HeaderPadrao>
            {dashboardStructure &&
                <TutorialBadge steps={
                    [
                        { title: 'Bem Vindo', text: 'Bem vindo ao Neuro Sistema! Passaremos por algumas etapas, para você aprender como utilizar o sistema! Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial.' },
                        { title: 'Informações Básicas', selector: '#header', text: 'Aqui você tem algumas informações básicas!' },
                        { title: 'Indicadores Importantes', selector: '#mostradores', text: 'Nesse local, você pode ver alguns indicadores importantes, passe o mouse por cima, para ver seus detalhes!' },
                        { title: 'Acesso Rápido', selector: '#acesso', text: 'Temos aqui alguns links de Acesso Rápido, clique para acessa-los!' },
                        { title: 'Recados Importantes', selector: '#recados', text: 'Nesse local, você pode ver alguns recados importantes, sempre se atente a isso!' },
                        { title: 'Finalização', text: <span>É basicamente isso, se você ainda tiver dúvidas, pode apertar novamente no botão de tutorial no canto inferior da tela, ou se preferir, <a href="/documentacao">acesse nossa documentação</a>!</span> },
                    ]
                } hasTopicos={tipo_user.toLowerCase() === 'desenvolvedor'} />
            }
        </>
    );
    //#endregion
}