import React, { useState, useRef, useEffect } from 'react';

const Dropdown = ({ button, substituicao, reserva, content, clickMode = false, buttonOver = false, defaultOpen = false, dropdownZIndex = 'initial', closeDelay = 200, setMostrando = () => { } }) => {
    let timeoutId;
    const [isHovered, setIsHovered] = useState(defaultOpen ? true : false);
    const dropdownRef = useRef(null);

    const handleMouseEnter = () => {
        clearTimeout(timeoutId);
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        timeoutId = setTimeout(() => {
            setIsHovered(false);
        }, closeDelay);
    };

    useEffect(() => {
        setMostrando(isHovered);
    }, [isHovered]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsHovered(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const getDropdownPositionClass = () => {
        if (substituicao) {
            return 'ns-dropdown-menu-right';
        } else if (reserva) {
            return 'ns-dropdown-menu-left';
        }
        return 'ns-dropdown-menu-default';
    };

    return (
        <>
            {clickMode ?
                <div
                    className={`ns-dropdown-parent ${isHovered ? 'hover' : ''}`}
                    onClick={isHovered ? handleMouseLeave : handleMouseEnter}
                    ref={dropdownRef}
                >
                    <div
                        type="button"
                        className={`ns-dropdown-btn p-0 m-0${buttonOver ? ' position-relative' : ''}`}
                        style={
                            (buttonOver && isHovered)
                                ? {
                                    zIndex: 100,
                                    transition: 'z-index 0.2s'
                                } : {
                                    zIndex: 'auto',
                                    transition: 'z-index 0.2s'
                                }}>
                        {button}
                    </div>
                    <div
                        className={`ns-dropdown-menu ${getDropdownPositionClass()}`}
                        style={buttonOver
                            ? {
                                zIndex: 99,
                                transition: 'z-index 0.2s',
                                position: 'absolute',
                            } : {
                                zIndex: dropdownZIndex,
                                transition: 'z-index 0.2s'
                            }}>
                        {content}
                    </div>
                </div>
                :
                <div
                    className={`ns-dropdown-parent ${isHovered ? 'hover' : ''}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={dropdownRef}
                >
                    <div
                        type="button"
                        className={`ns-dropdown-btn p-0 m-0${buttonOver ? ' position-relative' : ''}`}
                        style={(buttonOver && isHovered)
                            ? {
                                zIndex: 100,
                                transition: 'z-index 0.2s'
                            } : {
                                zIndex: 'auto',
                                transition: 'z-index 0.2s'
                            }}>
                        {button}
                    </div>
                    <div
                        className={`ns-dropdown-menu ${getDropdownPositionClass()}`}
                        style={buttonOver
                            ? {
                                zIndex: 99,
                                transition: 'z-index 0.2s',
                                position: 'absolute',
                            } : {
                                zIndex: dropdownZIndex ? dropdownZIndex : 'auto',
                                transition: 'z-index 0.2s'
                            }}>
                        {content}
                    </div>
                </div>
            }

        </>
    );
};

export default Dropdown;