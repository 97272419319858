import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import api from 'services/api';
import moment from 'moment';
import userGeneric from 'assets/img_avatar.png';
import { useAuth } from 'context/auth';
import Carregamento from 'components/Carregamento';
import DetalhesDoAgendamento from 'pages/TerapeutaDashboard/Agenda/Modal/DetalhesDoAgendamento';
import 'styles/reparticoes-css/agenda/agenda-modal.css';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';
import { getLegendaAgendamento } from 'helpers/agenda.helper';
import { listOfStyles } from 'pages/RecepcaoDashboard/Agenda/Terapeuta/styles';
import { toPng } from 'html-to-image';
import bgImage from 'assets/background-image.jpeg';
import { useSnackbar } from 'context/snackbar';
const AgendaPacienteModal = forwardRef(({ id = 'agendaModal', paciente_id, outSideSet = () => { }, temDetalhes = true }, ref) => {
    //#region VARIÁVEIS
    const [baseWidth, baseHeight] = [180, 120];
    const styles = listOfStyles(baseWidth, baseHeight);
    const snackbar = useSnackbar();
    /*===VARS TELA===*/
    const title = 'Agenda do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    /*===VARS VALIDACAO===*/
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [loading, setloading] = useState(false);

    const [paciente, setPaciente] = useState(null);
    const [horarios, setHorarios] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedPacienteId, setSelectedPacienteId] = useState();
    const [viewSize, setViewSize] = useState(window.innerWidth);
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'Segunda',
            short_label: 'S'
        },
        {
            value: 2,
            label: 'Terça',
            short_label: 'T'
        },
        {
            value: 3,
            label: 'Quarta',
            short_label: 'Q'
        },
        {
            value: 4,
            label: 'Quinta',
            short_label: 'Q'
        },
        {
            value: 5,
            label: 'Sexta',
            short_label: 'S'
        },
        {
            value: 6,
            label: 'Sábado',
            short_label: 'S'
        },
    ];

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 15
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: `${viewSize > 600 ? 80 : 100}vw`,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF",
            zIndex: 100,
            paddingTop: '0px',
        },
    };
    const elementRef = useRef();
    //#endregion
    //#region FUNÇÕES DA MODAL
    /**
     * Opens the modal and sets the selected paciente ID.
     * 
     * @param {string} paciente_id - The ID of the paciente.
     */
    const openModal = useCallback((paciente_id) => {
        setSelectedPacienteId(paciente_id);
        setPaciente(null);
        setAtendimentos([])
        setIsOpen(true);
        outSideSet(true);
    }, []);
    /**
     * Closes the modal and resets the selected patient ID.
     */
    const closeModal = useCallback(() => {
        setSelectedPacienteId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    /**
     * Callback function called after the modal is opened.
     */
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region HANDLES
    const handleExportToImage = (baixar) => {
        toPng(elementRef.current)
            .then((dataUrl) => {
                switch (baixar) {
                    case 'baixar':
                        snackbar.displayMessage('Baixando...');
                        const link = document.createElement('a');
                        link.download = `agenda-${moment().format('DD_MM_YYYY-HH_MM')}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        break;
                    case 'copiar':
                        fetch(dataUrl)
                            .then(res => res.blob())
                            .then(blob => {
                                navigator.clipboard.write([
                                    // eslint-disable-next-line no-undef
                                    new ClipboardItem({ 'image/png': blob })
                                ]).then(() => {
                                    snackbar.displayMessage('Imagem copiada para a área de transferência.');
                                }).catch((err) => {
                                    console.error('Erro ao copiar imagem: ', err);
                                });
                            })
                            .catch((err) => {
                                console.error('Erro ao gerar o blob da imagem: ', err);
                            });
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {
                console.error('Erro ao gerar imagem:', err);
            });
    }
    //#endregion
    //#region FUNCOEs
    /**
     * Retrieves a patient's information from the API.
     * 
     * @param {number} paciente_id - The ID of the patient.
     */
    const getPaciente = (paciente_id) => {
        if (paciente_id !== undefined && paciente_id !== null) {
            api.get(`api/paciente/${paciente_id}?with=diagnostico1,diagnostico2`)
                .then(({ data }) => {
                    setPaciente(data);
                });
        }
    }
    /**
     * Retrieves the agenda horarios from the API.
     */
    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        })
    }
    /**
     * Retrieves the agenda for a specific day.
     * 
     * @returns {void}
     */
    const getAgendaDia = () => {
        let paciente_id = selectedPacienteId;
        api.get(`/api/agendamento?filter[paciente_id]=${paciente_id}&with=estacao,estacao.sala,terapium,especialidade`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        })
    }
    /**
     * Opens the modal for displaying details of an appointment.
     *
     * @param {Object} detalhes - The details of the appointment.
     */
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    }
    //#endregion
    useEffect(() => {
        getAgendaHorarios();

        window.addEventListener('resize', () => {
            setViewSize(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setViewSize(window.innerWidth);
            });
        }
    }, []);
    useEffect(() => {
        try {
            if (selectedPacienteId !== undefined && selectedPacienteId !== null && horarios) {
                const carregaTudo = () => {
                    getPaciente(selectedPacienteId);
                    getAgendaDia();
                }
                setloading(true);
                setPaciente(null)
                setAtendimentos([]);
                carregaTudo();
                setloading(false);
            }
        } catch (e) {
            console.error("Erro ao carregar dados do paciente - ", e);
        }
    }, [selectedPacienteId, horarios]);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='d-flex justify-content-between'>
                    <div className='w-100vw'>
                        <div className='w-100 d-flex justify-content-between'>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('baixar')}>
                                Baixar Agenda
                            </button>
                            <button className='btn-padrao' type='button' onClick={() => handleExportToImage('copiar')}>
                                Copiar Agenda
                            </button>
                        </div>
                        <div className='w-100vw px-4 pb-4' style={{ backgroundImage: `url(${bgImage})`, }} ref={elementRef}>
                            <h4 className='text-center w-100per font-bold font-roxo pt-4 pb-2'>{paciente?.nome}</h4>
                            <table className='table-agenda'>
                                <thead className='thead'>
                                    <tr>
                                        <td key={'dias-da-semana'} align={'center'} className='tr1'>Horário</td>
                                        {diasDaSemanaArray.map(dia => (
                                            <td
                                                key={'dia-semana' + dia.value}
                                                style={{ width: baseWidth }}
                                                className={`text-center tr1${diaSemanaIndex === dia.value ? ' selecionado' : ''}`}>
                                                {dia.label}
                                            </td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {horarios.map((horario, horarioIdx) => {
                                        const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                        return (
                                            <tr key={`linha-horario-${horarioIdx}`}>
                                                <td align={'center'} className='tr-hora' style={{ height: baseHeight }}>{horasExtenso}</td>
                                                {diasDaSemanaArray.map(dia => {
                                                    const atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso && atendimento.tipo_atendimento_id !== 4 && atendimento.tipo_atendimento_id !== 6 && atendimento.tipo_atendimento_id !== 7 && atendimento.tipo_atendimento_id !== 8);
                                                    let content;
                                                    let div2style = [];
                                                    let legenda = [];
                                                    if (atendimentoEncontrado.length > 0) {
                                                        const atendimentoZero = atendimentoEncontrado[0];
                                                        console.log(atendimentoZero);
                                                        legenda = getLegendaAgendamento(atendimentoZero, atendimentos);
                                                        div2style = {
                                                            ...styles[legenda]
                                                        }
                                                        content = <div className='table-detaild-agenda'>
                                                            <span>
                                                                {atendimentoZero.estacao.sala.sala}/{atendimentoZero.estacao.estacao}
                                                            </span>
                                                            <span>
                                                                {atendimentoZero?.terapium?.terapia}
                                                            </span>
                                                            <span>
                                                                {atendimentoZero?.especialidade?.especialidade}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoZero?.terapeutum?.nome}
                                                            </span>
                                                        </div>
                                                    }
                                                    return < td
                                                        onClick={() => { if (temDetalhes && atendimentoEncontrado.length > 0) modalDetalhesDoAtendimento(atendimentoEncontrado.length > 0) }}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                        className={`tr-vazio text-left d-grid font-size-12px p-0 ${temDetalhes ? ' cursor-zoom' : ''}`}
                                                        style={{ ...div2style, width: baseWidth, height: baseHeight }}>
                                                        {content}
                                                    </td>
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-content-start max-w-300px min-w-300px'>
                        <div className='d-flex flex-column align-items-center bg-white w-100' style={{ boxShadow: "0 0 10px 0px silver", borderRadius: 10, height: 'auto' }}>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-center'>
                                    <img src={
                                        (paciente === null)
                                            ? userGeneric
                                            : (paciente.foto_perfil === null || paciente.foto_perfil === '')
                                                ? userGeneric
                                                : paciente.foto_perfil
                                    } height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' alt={''} />
                                </div>
                                <div className='d-flex flex-column m-2'>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Nome:
                                        </label> {paciente?.nome}
                                    </span>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Idade:
                                        </label> {moment(paciente?.nascimento).diff(moment(), 'years') * -1}
                                    </span>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Diagnóstico:
                                        </label>
                                        <ul>
                                            <li>{paciente?.diagnostico1?.diagnostico}</li>
                                            <li>{paciente?.diagnostico2?.diagnostico}</li>
                                        </ul>
                                    </span>

                                </div>
                            </div>
                            <div className='d-flex justify-arround col-12'>
                                <a className='btn-padrao mb-2' href={`/dashboard/${user.tipo_user}/pacientes/perfil/${paciente_id}`} target='blank'>Cadastro</a>
                                <a className='btn-padrao mb-2' href={`/dashboard/${user.tipo_user}/pacientes/prontuarios/${paciente_id}`} target='blank'>Prontuário</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            {
                isModalDetalhesDoAtendimentoVisible
                    ? <DetalhesDoAgendamento agendamentoId={atendimentoId} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                    : (<></>)
            }
        </>
    )
});
export default AgendaPacienteModal;