import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

/**
 * AuthProvider component.
 * 
 * @component
 * @example
 * // Usage
 * import AuthProvider from './auth.jsx';
 * 
 * function App() {
 *   return (
 *     <AuthProvider>
 *       {/* Your app content here *\/}
 *     </AuthProvider>
 *   );
 * }
 */
const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasDocumentosPendentes, setHasDocumentosPendentes] = useState(false);
	const [hasDocumentosObrigatoriosPendentes, setHasDocumentosObrigatoriosPendentes] = useState(false);
	const [documentosPendentes, setDocumentosPendentes] = useState([]);
	const [data, setData] = useState(() => {
		try {
			const access_token = sessionStorage.getItem("@Neuro:access_token");
			const refresh_token = sessionStorage.getItem("@Neuro:access_token");
			const user = sessionStorage.getItem("@Neuro:user");

			if (access_token && user) {
				api.defaults.headers.authorization = `Bearer ${access_token}`;
				return { access_token, refresh_token, user: JSON.parse(user) };
			}
		} catch (error) {
			sessionStorage.clear();
			console.error(error);
		}
		return {};
	});

	/**
	 * Verifica se existe algum documento pendente para o usuário.
	 * Faz uma requisição para a API e armazena os documentos pendentes no sessionStorage.
	 */
	const verificaExisteDocumentoPendente = () => {
		try {
			api.get("/api/user/documento-administrativo/pendente")
				.then(({ data }) => {
					setDocumentosPendentesSessionStorage(data);
				});
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Realiza o logout do usuário.
	 * Remove os dados do usuário do sessionStorage e redireciona para a página inicial.
	 */
	const signOut = useCallback(() => {
		try {
			sessionStorage.removeItem("@Neuro:user");
			sessionStorage.removeItem("@Neuro:access_token");
			sessionStorage.removeItem("@Neuro:docs_pending");
			sessionStorage.removeItem("@Neuro:refresh_token");
			setData({});
			navigate(`/`);
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Armazena os documentos pendentes no sessionStorage.
	 * @param {Array} documentos - Lista de documentos pendentes.
	 */
	function setDocumentosPendentesSessionStorage(documentos) {
		try {
			if (typeof documentos === 'object') {
				const documentosObrigatorios = documentos.filter(documento => documento.tipo_documento === "obrigatorio");
				setHasDocumentosObrigatoriosPendentes(documentosObrigatorios.length > 0);
				setHasDocumentosPendentes(documentos.length > 0);
				if (documentos.length > 0) {
					sessionStorage.setItem("@Neuro:docs_pending", JSON.stringify(documentos));
					return;
				}
				sessionStorage.removeItem("@Neuro:docs_pending");
			}
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Realiza o login do usuário.
	 * Faz uma requisição para a API de login e armazena os tokens e dados do usuário no sessionStorage.
	 * @param {Object} param0 - Objeto contendo username e password.
	 * @returns {Object} response - Resposta da API.
	 */
	const signIn = useCallback(async ({ username, password }) => {
		try {
			const response = await api.post("api/login", {
				"email": username, "password": password,
			});

			if (response?.status === 404 || response?.status === 401 || response?.data?.message === "Invalid Credentials" || response?.data?.message === "Acesso expirou") {
				return response;
			}

			const { refresh_token, access_token, user } = response?.data;

			setIsAuthenticated(true);

			// Limpa a sessão
			sessionStorage.clear();
			sessionStorage.clear();

			sessionStorage.setItem("@Neuro:access_token", access_token);
			sessionStorage.setItem("@Neuro:refresh_token", refresh_token);
			api.defaults.headers.authorization = `Bearer ${access_token}`;

			sessionStorage.setItem("@Neuro:user", JSON.stringify(user));

			setData({ access_token, user });

			setDocumentosPendentesSessionStorage(user?.documentos_pendentes);
			return response;
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Verifica se o usuário possui uma permissão específica.
	 * @param {number} Id - ID da permissão.
	 * @returns {boolean} - Retorna true se o usuário possui a permissão, caso contrário false.
	 */
	const getPermissionID = (Id) => {
		try {
			const filtraPagos = data?.user?.permissions?.filter((permissao) => permissao.id === Number(Id));
			return filtraPagos.length > 0;
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Altera o tipo de usuário.
	 * Atualiza o tipo de usuário no sessionStorage e adiciona a lista de permissões.
	 * @param {string} tipo_user - Novo tipo de usuário.
	 */
	const changeTipoUser = useCallback((tipo_user) => {
		try {
			let user = JSON.parse(sessionStorage.getItem('@Neuro:user'));
			const lista_de_permissoes = api.get('api/group?with=permission')
				.then((response) => response.data);
			user = {
				...user,
				tipo_user: tipo_user,
				lista_de_permissoes: lista_de_permissoes
			};
			sessionStorage.setItem("@Neuro:user", JSON.stringify(user));
		} catch (error) {
			console.error(error);
		}
	}, []);

	/**
	 * Atualiza os dados do usuário.
	 * Armazena os novos dados do usuário no sessionStorage.
	 * @param {Object} user - Objeto contendo os dados do usuário.
	 */
	const updateUser = useCallback((user) => {
		try {
			sessionStorage.setItem("@Neuro:user", JSON.stringify(user));
			setData({
				token: data.token, user,
			});
		} catch (error) {
			console.error(error);
		}
	}, [setData, data.token]);

	useEffect(() => {
		try {
			if (!hasDocumentosObrigatoriosPendentes) return;
			navigate('/documentos-pendentes');
		} catch (error) {
			console.error(error);
		}
	}, [hasDocumentosObrigatoriosPendentes]);

	return (
		<AuthContext.Provider
			value={{
				user: data.user, signIn, signOut, updateUser, isAuthenticated, setIsAuthenticated, setData, getPermissionID, changeTipoUser,
				documentosPendentes, hasDocumentosPendentes, hasDocumentosObrigatoriosPendentes, verificaExisteDocumentoPendente
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function useAuth() {
	return useContext(AuthContext);
}

export { AuthProvider, useAuth };
