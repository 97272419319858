import React, { useEffect, useState } from 'react';
import { HeaderContent } from './styles';
import '../../styles/global.css';
import { useAuth } from '../../context/auth';
import FuncoesUsuario from '../FuncoesUsuario';
import HeaderTerapeuta from '../HeaderTerapeuta';
import { MdMenu } from 'react-icons/md';
import NavLink from './NavLink';
import SubMenu from '../SubMenu';
import api from '../../services/api';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Represents the Header component.
 * 
 * @component
 * @returns {JSX.Element} The rendered Header component.
 */
const Header = ({ onSubMenuToggle = () => { } }) => {
    //#region VARIAVEIS
    const usuario = useAuth();
    const [openMenu, setOpenMenu] = useState(false);
    const [vetorPathname, setVetorPathname] = useState([]);
    const [subMenuLinks, setSubMenuLInks] = useState(null);
    const [subLinks, setSubLinks] = useState(null);
    const [headerItens, setHeaderItens] = useState(null);
    const [hasSubMenu, setHasSubMenu] = useState(false);
    const [tituloAtual, setTituloAtual] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const [quantidadeDeItens, setQuantidadeDeItens] = useState(0);
    const [atualLocal, setAtualLocal] = useState('');
    const [lista_de_permissoes, setListaDePermissoes] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const [isSubMenuMinimized, setIsSubMenuMinimized] = useState(false);
    //#endregion

    // Debounce function
    const debounce = (func, wait) => {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    // Responsividade melhorada com window.matchMedia
    const checkMobileView = () => {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        setIsMobileView(isMobile);
        setOpenMenu(!isMobile);
    };

    /**
     * Generates navigation items based on user permissions and updates the header state.
     *
     * This function creates a list of navigation links (`links`) and sub-links (`subLinksLocal`) 
     * based on the user's permissions (`lista_de_permissoes`). It also sets various states 
     * such as `hasSubMenu`, `tituloAtual`, `quantidadeDeItens`, `subLinks`, and `headerItens`.
     *
     * @function
     * @returns {void}
     */
    const getGenericItens = () => {
        let links = [];
        let saveLinks = [];
        let tipo_user = usuario?.user.tipo_user.toLowerCase();
        let subLinksLocal = [];
        let quantidadeItens = 0;

        links.push(
            <NavLink key={'home/dashboard/' + tipo_user + '/'}
                link={'/dashboard/' + tipo_user + '/'}
                name={'Menu Inicial'}
                icon={'home'}
                atualLocal={atualLocal}
                notificacao={0} />
        );

        if (lista_de_permissoes) {
            lista_de_permissoes.forEach((el) => {
                if (!saveLinks.includes(el.link) && el.linkable) {
                    const link = '/dashboard/' + tipo_user + el.link;
                    const isPage = '/' + atualLocal;
                    const page = '/' + el.link.split('/')[1];

                    if (isPage === page) {
                        setHasSubMenu(el.has_submenu);
                        subLinksLocal.push({ label: el.title, url: link, position: el.submenu_position });
                    }

                    if (link === location.pathname) setTituloAtual(el.title);

                    if (!el.sublinkable) {
                        saveLinks.push(el.link);
                        const subLinks = lista_de_permissoes
                            .filter(item => (item.link && `/${link.split('/')[3]}` === `/${item.link.split('/')[1]}` && item.linkable && item.sublinkable))
                            .map(item => ({ label: item.title, link: '/dashboard/' + tipo_user + item.link, position: item.submenu_position }));

                        subLinks.push({ label: el.title, link: link, position: el.submenu_position });

                        const subLinksFinal = subLinks.length > 1 ? subLinks : null;
                        quantidadeItens++;
                        links.push(
                            <NavLink
                                key={el.title + link}
                                link={link}
                                name={el.title}
                                icon={el.icon}
                                atualLocal={atualLocal}
                                notificacao={0}
                                dropDownList={subLinksFinal}
                                clicable={subLinksFinal?.length <= 0}
                            />
                        );
                    }
                }
            });
        }

        setQuantidadeDeItens(usuario?.user.tipo_user.toLowerCase() === 'terapeuta' ? quantidadeItens + 5 : quantidadeItens);
        setSubLinks(subLinksLocal);
        setHeaderItens(links);
    };

    // Atualiza as informações do usuário
    const updateUserInformation = async () => {
        try {
            const { data: user } = await api.get('api/user/' + usuario?.user.id);
            if (usuario?.user !== user) {
                usuario.updateUser(user);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Executa a inicialização da página
    /**
     * Asynchronous function to handle user access validation and load generic items.
     * 
     * This function checks if the logged-in user is an auditor and verifies if their access has expired.
     * If the access is expired, it displays an alert and navigates to the home page.
     * After the validation, it loads generic items.
     * 
     * @async
     * @function onLoad
     * @returns {Promise<void>} A promise that resolves when the function completes.
     */
    const onLoad = async () => {
        if (usuario?.user.tipo_user.toLowerCase() === 'auditor') {
            const dataAtual = new Date();
            const dataTeste = new Date(usuario?.user.limite_acesso);

            if (dataAtual > dataTeste) {
                Swal.fire("Acesso expirado!", "Parece que seu acesso expirou, converse com a coordenação para receber a liberação, e a nova senha.", "error")
                    .then(() => navigate('/'));
            }
        }

        await getGenericItens();
    };

    // Função para lidar com toggle do submenu
    const handleSubMenuToggle = (isMinimized) => {
        setIsSubMenuMinimized(isMinimized);
    };

    //#region USE EFFECT
    useEffect(() => {
        onSubMenuToggle(isSubMenuMinimized);
    }, [isSubMenuMinimized]);

    useEffect(() => {
        if (!usuario?.user) {
            navigate('/');
        }
        if (lista_de_permissoes) {
            onLoad();
            checkMobileView();
        }
        const pathArray = location?.pathname?.split('/');
        if (pathArray.length > 3) {
            setAtualLocal(pathArray[3]);
        } else {
            setAtualLocal('');
        }
        setVetorPathname(pathArray);

        const handleLoad = () => {
            sessionStorage.removeItem('@Neuro');
        };

        window.addEventListener('load', handleLoad);
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, [location.pathname, usuario, lista_de_permissoes]);

    // UseEffect para setar lista de permissões
    useEffect(() => {
        if (usuario?.user?.lista_de_permissoes?.length > 0) {
            setListaDePermissoes(usuario.user.lista_de_permissoes[0].permissions);
        }
    }, [usuario]);

    useEffect(() => {
        checkMobileView();
    }, [quantidadeDeItens]);

    useEffect(() => {
        document.title = tituloAtual === null ? 'Neuro Sistema' : 'Neuro Sistema - ' + tituloAtual;
    }, [tituloAtual]);

    useEffect(() => {
        if (subLinks) {
            const subLinksLocal = subLinks.sort((a, b) => a.position - b.position);
            setSubMenuLInks(subLinksLocal);
        }
    }, [subLinks]);

    useEffect(() => {
        const debouncedCheckMobileView = debounce(checkMobileView, 200);
        window.addEventListener('resize', debouncedCheckMobileView);
        return () => {
            window.removeEventListener('resize', debouncedCheckMobileView);
        };
    }, [quantidadeDeItens]);

    useEffect(() => {
        const handleResize = debounce(() => {
            checkMobileView();
        }, 200);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    //#endregion

    return (
        <>
            <nav className={`sys-navbar d-flex justify-between align-items-center`} id='navAll'>
                <a key={0} href={'/dashboard/' + usuario?.user?.tipo_user?.toLowerCase() + '/'} className="nav-logo">
                    <img style={{ maxWidth: '150px', height: 'auto' }} src={'/logo.png'} alt="Neuro" />
                </a>
                <div className='m-0 p-0 d-flex flex-wrap justify-center'>
                    {isMobileView && <MdMenu size={30} onClick={() => setOpenMenu(!openMenu)} />}
                    {openMenu && (
                        <HeaderContent className={(isMobileView ? "w-300px position-absolute ns-two-per-line " : "") + "nav-collapse bg-roxo flex-wrap"} style={isMobileView ? { marginTop: '60px' } : {}} id="navContent">
                            {headerItens}
                            {usuario?.user.tipo_user.toLowerCase() === 'terapeuta' && <HeaderTerapeuta />}
                        </HeaderContent>
                    )}
                </div>
                <FuncoesUsuario key={'funcoes-usuario-1'} className='nav-usuario' menu={<></>} isMobileView={isMobileView} />
            </nav>
            <div className="gamb"></div>
            {hasSubMenu && subLinks && subLinks?.length > 0 && (
                <SubMenu
                    selectedItem={vetorPathname?.length > 0 ? vetorPathname[vetorPathname.length - 1] : ''}
                    links={subMenuLinks}
                    defaultClose={vetorPathname?.length > 0 && vetorPathname[vetorPathname.length - 1] === 'controlediario'}
                    onToggleMinimized={handleSubMenuToggle}
                />
            )}

        </>
    );
};

export default Header;
