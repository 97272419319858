//#region IMPORTAÇÕES
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Container } from './styles';
import api from "../../../services/api";
import { TablePaciente } from "./TablePaciente";
import FiltroPaciente from "../../../components/Filter/FiltroPacientes/FiltroPaciente";
import { useAuth } from "../../../context/auth";
import Carregamento from "components/Carregamento";
import { BsGrid, BsList } from "react-icons/bs";
import TableProntuarios from "./TableProntuario";
//#endregion
export default function ListagemPacientes() {
    //#region VARIAVEIS
    const navigate = useNavigate();
    const usuario = useAuth();
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [paciente, setPaciente] = useState([]);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [pacienteIdentList, setPacienteIdentList] = useState(null);
    const [permissoes, setPermissoes] = useState([]);
    //VISUALIZAÇÃO\\
    const [viewMode, setViewMode] = useState('list');
    //#endregion
    //#region FUNCOES
    const coletaPermissoes = () => {
        let permissoes = usuario.user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        setLoadingDadosPacientes(true);
        async function loadPacientes() {
            api.get(`api/paciente/lista/simples?with=responsavel&select=id,nome,identificador,status,created_at,nascimento,responsavel.nome`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPacienteOriginal(data);
                setPaciente(data);
                setLoadingDadosPacientes(false);
            })
        }
        loadPacientes();

        coletaPermissoes();
    }, []);
    useEffect(() => {
        let idList = [];
        pacienteOriginal.forEach((data) => {
            if (data.status !== 'Inativo') {
                if (data.identificador !== null) {
                    idList.push(data.identificador)
                };
            };
        });
        setPacienteIdentList(idList);
    }, [pacienteOriginal, loadingDadosPacientes])
    //#endregion
    //#region HTML
    return (
        <Container>
            {loadingDadosPacientes ? <Carregamento /> : ''}
            <div className="d-flex justify-content-between align-items-center w-100">
                <div className="col-10 text-left">
                    <FiltroPaciente resultRef={setPaciente} list={pacienteOriginal} />
                </div>
                {permissoes['prontuários'] &&
                    <div className="col-2 text-left">
                        <button onClick={() => setViewMode(prev => prev === 'list' ? 'grid' : 'list')}>
                            {viewMode === 'grid'
                                ? <BsList size={25} />
                                : <BsGrid size={25} />
                            }
                        </button>
                    </div>
                }
            </div>
            <div style={{ marginBottom: 20 }}>
                {viewMode === 'grid' && <TableProntuarios paciente={paciente} />}
                {viewMode === 'list' &&
                    <TablePaciente source={paciente} pacienteIdentList={pacienteIdentList} permissoes={permissoes} usuario={usuario} />
                }
            </div>
            {
                permissoes['criação'] &&
                <div className="d-flex align-items-center justify-content-center">
                    <button onClick={() => navigate(`/dashboard/${usuario.user.tipo_user.toLowerCase()}/pacientes/novo`, { state: { pacienteIdentList } })} className="btn-padrao">
                        <AiOutlinePlusCircle style={{ marginRight: 5 }} />
                        Cadastrar Paciente
                    </button>
                </div>
            }
        </Container>
    )
    //#endregion
}