import { useState } from "react";
import api from "services/api";

const useTerapeutasData = () => {
    const [terapeutasOptions, setTerapeutasOptions] = useState([]);

    const fetchTerapeutasData = async () => {
        try {
            const { data } = await api.get(`api/terapeuta`);
            const options = data.map((terapeuta) => ({
                value: terapeuta.id,
                label: terapeuta.nome
            }));
            setTerapeutasOptions(options);
        } catch (error) {
            console.error('Erro ao carregar terapeutas:', error);
        }
    };

    return { terapeutasOptions, fetchTerapeutasData };
};

export default useTerapeutasData;