import React, { forwardRef } from "react";
import moment from "moment";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { BsCheck, BsTrashFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { ChangeCase } from "services/ChangeCase";
import DropdownCSS from "components/DropdownCSS";
import './agenda.css';

/**
 * TbodyAgendaSemanal is a React forwardRef component that renders a table body with scheduled appointments.
 * It processes and displays appointments, reservations, and substitutions with appropriate styles and tooltips.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.memorizedHorarios - Array of memorized schedules.
 * @param {Array} props.memorizedEstacaos - Array of memorized stations.
 * @param {Object} props.newAgendamentos - Object containing new appointments.
 * @param {Object} props.styles - Object containing styles for the component.
 * @param {Function} [props.getLegendaAgendamento] - Function to get the appointment legend.
 * @param {Function} [props.setMostrandoReserva] - Function to set the showing reservation state.
 * @param {Array} props.agendamentos - Array of appointments.
 * @param {Object} props.styles_filtred - Object containing filtered styles.
 * @param {Object} props.styles_escuro - Object containing dark styles.
 * @param {Object} props.filtered - Object containing filtered styles.
 * @param {Object} props.permissoes - Object containing user permissions.
 * @param {Function} props.setTerapeutaSelecionado - Function to set the selected therapist.
 * @param {Function} props.aprovaReserva - Function to approve a reservation.
 * @param {Function} props.excluirAgendamento - Function to delete an appointment.
 * @param {Function} props.modalEditar - Function to open the edit modal.
 * @param {Function} props.modalDetalhesAgendamento - Function to open the appointment details modal.
 * @param {boolean} props.esperaMode - Boolean indicating if the wait mode is active.
 * @param {Object} props.usuario - Object containing user information.
 * @param {Function} props.setPacienteSelecionadoOpt - Function to set the selected patient option.
 * @param {Function} [props.handleOpenAgendamento] - Function to handle opening an appointment.
 * @param {Function} [props.handleOnMouseEnter] - Function to handle mouse enter event.
 * @param {Function} [props.handleOnMouseLeave] - Function to handle mouse leave event.
 * @param {Function} [props.onScrollCapture] - Function to handle scroll capture event.
 * @param {Function} [props.endLoad] - Function to handle end load event.
 * @param {boolean} props.showAgendamentos - Boolean indicating if appointments should be shown.
 * @param {React.Ref} ref - React ref forwarded to the tbody element.
 * @returns {JSX.Element} A table body element with the processed appointment information.
 *
 * @example
 * <TbodyAgendaSemanal
 *   memorizedHorarios={memorizedHorarios}
 *   memorizedEstacaos={memorizedEstacaos}
 *   newAgendamentos={newAgendamentos}
 *   styles={styles}
 *   getLegendaAgendamento={getLegendaAgendamento}
 *   setMostrandoReserva={setMostrandoReserva}
 *   agendamentos={agendamentos}
 *   styles_filtred={styles_filtred}
 *   styles_escuro={styles_escuro}
 *   filtered={filtered}
 *   permissoes={permissoes}
 *   setTerapeutaSelecionado={setTerapeutaSelecionado}
 *   aprovaReserva={aprovaReserva}
 *   excluirAgendamento={excluirAgendamento}
 *   modalEditar={modalEditar}
 *   modalDetalhesAgendamento={modalDetalhesAgendamento}
 *   esperaMode={esperaMode}
 *   usuario={usuario}
 *   setPacienteSelecionadoOpt={setPacienteSelecionadoOpt}
 *   handleOpenAgendamento={handleOpenAgendamento}
 *   handleOnMouseEnter={handleOnMouseEnter}
 *   handleOnMouseLeave={handleOnMouseLeave}
 *   onScrollCapture={onScrollCapture}
 *   endLoad={endLoad}
 *   showAgendamentos={showAgendamentos}
 *   ref={ref}
 * />
 */
const TbodyAgendaSemanal = forwardRef(({
    memorizedHorarios,
    memorizedEstacaos,
    newAgendamentos,
    styles,
    getLegendaAgendamento = () => { },
    setMostrandoReserva = () => { },
    agendamentos,
    styles_filtred,
    styles_escuro,
    filtered,
    permissoes,
    setTerapeutaSelecionado,
    aprovaReserva,
    excluirAgendamento,
    modalEditar,
    modalDetalhesAgendamento,
    esperaMode,
    usuario,
    setPacienteSelecionadoOpt,
    handleOpenAgendamento = () => { },
    handleOnMouseEnter = () => { },
    handleOnMouseLeave = () => { },
    onScrollCapture = () => { },
    endLoad = () => { },
    showAgendamentos = false,
}, ref) => {
    /**
     * Generates a dropdown agenda component for a given appointment.
     *
     * @param {Object} a - The appointment object.
     * @param {string} tipo - The type of the appointment (e.g., 'reserva', 'substituicao').
     * @param {boolean} horaInteira - Indicates if the appointment is for a full hour.
     * @param {string} estacao - The station associated with the appointment.
     * @param {number} idHorario - The ID of the schedule.
     * @returns {JSX.Element} The dropdown agenda component.
     */
    const geraDropDownAgenda = (a, tipo, horaInteira, estacao, idHorario) => {
        const legenda = getLegendaAgendamento(a, agendamentos);
        let horaFinalDiferente;
        let horaFinal;
        if (a.data_atendimento_final) {
            if (a.data_atendimento_final.includes('T')) {
                horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
            } else {
                horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
            }
        }
        horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
        let div2style = {
            cursor: 'default',
            marginTop: '-10px',
            ...styles.trReserva
        };

        if (a.filtrado === undefined) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles[legenda],
            };
        } else if (a.filtrado === true) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles_filtred[legenda],
                ...filtered,
            };
        } else if (a.filtrado === false) {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
                ...styles_escuro[legenda],
            };
        }

        if (tipo === 'substituicao') {
            div2style = {
                ...div2style,
                backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
            };
        }

        return (
            <div style={styles.agendamentoExtras} key={a.id}>
                <DropdownCSS
                    substituicao={tipo !== 'reserva'}
                    reserva={tipo === 'reserva'}
                    setMostrando={setMostrandoReserva}
                    clickMode={false}
                    defaultOpen={false}
                    dropdownZIndex={100}
                    button={
                        <svg
                            className='p-0 m-0'
                            style={{
                                ...styles.extraIcon,
                                ...(tipo === 'reserva'
                                    ? styles.extraIcon.reserva
                                    : (a.tipo_atendimento_id === 7)
                                        ? styles.extraIcon.substituicaoFeito
                                        : styles.extraIcon.substituicao
                                )
                            }}
                            id={`${tipo === 'reserva' ? 'agendamento-reserva-icone' : 'agendamento-substituicao-icone'}${a.id}`}
                            width="20"
                            height="20"
                            fill="currentColor"
                        >
                            <polygon points="0,0 15,0 0,15" />
                        </svg>
                    }
                    content={
                        <div id={`${tipo === 'reserva' ? 'agendamento-reserva' : 'agendamento-substituicao'}${a.id}`} style={div2style}>
                            <div className={horaFinalDiferente ? 'd-flex h-100per flex-wrap p-1' : 'd-flex h-100per align-content-between flex-wrap'}>
                                <div className='col-12' style={a.paciente_status === 'Ferias' ? styles.fontFerias : {}}>
                                    {a.paciente_identificador}
                                </div>
                                <div className='col-12 d-flex justify-content-between align-items-center'>
                                    <AiFillEye className='pointer h-azul' onClick={() => modalDetalhesAgendamento(a)} />
                                    {permissoes['criacao_edicao'] && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
                                        <AiFillEdit className='pointer h-azul' onClick={() => modalEditar(a, horaInteira, estacao, idHorario, a.reserva, a.tipo_atendimento_id)} />
                                    }
                                    {permissoes['exclusão'] &&
                                        <BsTrashFill className='pointer h-azul' onClick={() => excluirAgendamento(a.id)} />
                                    }
                                    {permissoes['criacao_edicao'] && legenda === 'reserva' &&
                                        <BsCheck size={22} className='pointer h-azul' onClick={() => aprovaReserva(a)} />
                                    }
                                </div>
                                <div className='col-12' onClick={() => { setTerapeutaSelecionado(a.terapeuta_id) }} style={a.terapeuta_status === 'Ferias' ? styles.fontFerias : {}}>
                                    {a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 10) : 'Sem Terapeuta'}.
                                </div>
                                {!horaFinalDiferente &&
                                    <div className='hora-diferente d-inline-flex'>
                                        Hora Final: {horaFinal}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                />
            </div>
        );
        ;
    };
    const Cell = ({ estacao, horario }) => {
        const horaInteira = moment.utc(horario.horario);
        const hora = moment.utc(horario.horario).format('HH:mm');
        const idHorario = horario.id;

        const agendamento = newAgendamentos[estacao.id]?.[horario.id] || null;

        let agendaContent = null;
        let reservaNoTitulo = null;
        let substituicaoNoTitulo = null;
        let titulo;
        let tooltipInfo = <>
            Sala/Estação: {estacao.sala.sala} {estacao.estacao} ({estacao.id})<br></br>
            Hora: {hora} ({horario.id})
        </>;
        let div2styleAgenda;

        const processAgendamento = (a) => {
            const aReserva = a.reserva === 0;
            const aTipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7;
            const aEspera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;

            if (aReserva && aTipo && aEspera) {
                agendaContent = [];
                const legenda = getLegendaAgendamento(a, agendamentos);
                let horaFinalDiferente;
                let horaFinal;
                if (a.data_atendimento_final) {
                    const finalTime = a.data_atendimento_final.includes('T') ?
                        String(a.data_atendimento_final).split('T')[1].split('.')[0] :
                        String(a.data_atendimento_final).split(' ')[1].split('.')[0];
                    horaFinalDiferente = finalTime === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                    horaFinal = finalTime ? finalTime.split(':') : null;
                }
                horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
                div2styleAgenda = {
                    cursor: 'default',
                    ...styles.trOcupado,
                    ...(a.filtrado === undefined ? styles[legenda] : a.filtrado ? { ...styles_filtred[legenda], ...filtered } : styles_escuro[legenda])
                };
                tooltipInfo = <>
                    {(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente' || legenda === 'conflitoTerapeutaSala' || legenda === 'duplicado') &&
                        <h5>{ChangeCase.toTitleCase(legenda)}</h5>
                    }
                    Sala/Estação: {estacao.sala.sala} / {estacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${estacao.id})`}<br></br>
                    Paciente: {a.paciente_nome} {a.paciente_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}<br></br>
                    Terapeuta: {a.terapeuta_nome} {a.terapeuta_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}<br></br>
                    Terapia: {a.terapia_terapia}<br></br>
                    Especialidade: {a.especialidade_especialidade}<br></br>
                    Horário: {hora}  {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}
                </>;
                titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })} style={a.paciente_status === 'Ferias' ? styles.fontFerias : {}}>
                    {a.paciente_identificador}
                </p>;

                agendaContent.push(<>
                    <div className='col-12 d-flex justify-content-center align-items-center h-auto-i' id={'agendamento' + a.id}>
                        <AiFillEye className='pointer h-azul mx-1' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
                        {permissoes['criacao_edicao'] && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
                            <AiFillEdit className='pointer h-azul mx-1' onClick={() => modalEditar(a, horaInteira, estacao, idHorario, a.reserva, a.tipo_atendimento_id)}></AiFillEdit>
                        }
                        {permissoes['exclusão'] &&
                            <BsTrashFill className='pointer h-azul mx-1' onClick={() => excluirAgendamento(a.id)} />
                        }
                    </div>
                    <div className='col-12 h-auto-i pointer ns-abrevia-texto' onClick={(e) => { setTerapeutaSelecionado(a.terapeuta_id) }} style={a.terapeuta_status === 'Ferias' ? styles.fontFerias : {}}>
                        {a.terapeuta_nome_curto}
                    </div>
                    {!horaFinalDiferente &&
                        <div className='hora-diferente d-inline-flex' width={styles.width}>
                            Hora Final: {horaFinal}
                        </div>
                    }
                </>);
            }
            const rReserva = a.reserva === 1;
            const rEspera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
            if (rReserva && rEspera) {
                reservaNoTitulo = geraDropDownAgenda(a, 'reserva', horaInteira, estacao, idHorario);
            }
            const sSubstituicao = !esperaMode ? a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7 : false;
            if (sSubstituicao && aReserva) {
                substituicaoNoTitulo = geraDropDownAgenda(a, 'substituicao', horaInteira, estacao, idHorario);
            }
        };

        if (agendamento?.length > 0) {
            agendamento.forEach(processAgendamento);
        }

        return (
            <td style={{ ...styles.trVazio, ...styles.tamanhoPadrao, cursor: permissoes['criacao_edicao'] ? 'pointer' : 'default', margin: 0, padding: 0, border: 'none', zIndex: 1 }}
                id={`linha-agendamento-${estacao.id}-${horario.id}`}
                data-tooltip-id={"tooltip-" + hora + "-" + estacao.id}
                onClick={(e) => { if (agendaContent === null) { handleOpenAgendamento(horaInteira, estacao, idHorario, e) } }}
            >
                <div className={'d-grid '} style={{ ...div2styleAgenda, ...styles.tamanhoPadrao }} id={`linha-agendamento-${estacao.id}-${horario.id}-div`}>
                    <div className='w-100per d-flex h-15px m-0 p-0'>
                        {reservaNoTitulo ? reservaNoTitulo : <div style={styles.agendamentoExtras}></div>}
                        <div style={styles.divTitulo}>{titulo || ' '}</div>
                        {substituicaoNoTitulo ? substituicaoNoTitulo : <div style={styles.agendamentoExtras}></div>}
                    </div>
                    {agendaContent}
                </div>
                <Tooltip id={"tooltip-" + hora + "-" + estacao.id} style={{ ...styles.agendamentoTooltip, zIndex: 20 }} place="top" effect="solid" closeOnScroll={true}>
                    {tooltipInfo}
                </Tooltip>
            </td >
        );
    };
    /**
     * CellCss component renders a table cell with scheduling information for a specific station and time slot.
     * It handles different types of appointments, including regular appointments, reservations, and substitutions.
     * The component also displays tooltips with detailed information about the appointment when hovered over.
     *
     * @component
     * @param {Object} props - The properties object.
     * @param {Object} props.estacao - The station object containing information about the station.
     * @param {Object} props.horario - The time slot object containing information about the time slot.
     * @param {number} props.horarioIndex - The index of the time slot in the list of time slots.
     * @param {number} props.estacaoIndex - The index of the station in the list of stations.
     * @returns {JSX.Element} The rendered table cell component.
     */
    const CellCss = ({ estacao, horario, horarioIndex, estacaoIndex }) => {
        const horaInteira = moment.utc(horario.horario);
        const hora = moment.utc(horario.horario).format('HH:mm');
        const idHorario = horario.id;

        const agendamento = newAgendamentos[estacao.id]?.[horario.id] || null;

        let agendaContent = null;
        let reservaNoTitulo = null;
        let substituicaoNoTitulo = null;
        let titulo;
        let tooltipInfo = (
            <ul>
                <li>Sala/Estação: <b>{estacao.sala.sala}/{estacao.estacao}</b></li>
                <li>Hora: <b>{hora}</b></li>
            </ul>
        );
        let div2styleAgenda;

        const processAgendamento = (a) => {
            const aReserva = a.reserva === 0;
            const aTipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7;
            const aEspera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;

            if (aReserva && aTipo && aEspera) {
                agendaContent = [];
                const legenda = getLegendaAgendamento(a, agendamentos);
                let horaFinalDiferente;
                let horaFinal;
                if (a.data_atendimento_final) {
                    const finalTime = a.data_atendimento_final.includes('T') ?
                        String(a.data_atendimento_final).split('T')[1].split('.')[0] :
                        String(a.data_atendimento_final).split(' ')[1].split('.')[0];
                    horaFinalDiferente = finalTime === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
                    horaFinal = finalTime ? finalTime.split(':') : null;
                }
                horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';

                // Aplicando os estilos conforme as regras
                div2styleAgenda = {
                    cursor: 'default',
                    ...styles.trOcupado,
                    ...(a.filtrado === undefined ? styles[legenda] : a.filtrado ? { ...styles_filtred[legenda], ...filtered } : styles_escuro[legenda])
                };

                tooltipInfo = (
                    <ul>
                        {(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente' || legenda === 'conflitoTerapeutaSala' || legenda === 'duplicado') &&
                            <li><h5>{ChangeCase.toTitleCase(legenda)}</h5></li>
                        }
                        <li>Sala/Estação: {estacao.sala.sala}/{estacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${estacao.id})`}</li>
                        <li>Paciente: {a.paciente_nome} {a.paciente_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}</li>
                        <li>Terapeuta: {a.terapeuta_nome} {a.terapeuta_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}</li>
                        <li>Terapia: {a.terapia_terapia}</li>
                        <li>Especialidade: {a.especialidade_especialidade}</li>
                        <li>Horário: {hora} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}</li>
                    </ul>
                );

                titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })} style={a.paciente_status === 'Ferias' ? styles.fontFerias : {}}>
                    {a.paciente_identificador}
                </p>;

                agendaContent.push(<>
                    <div className='col-12 d-flex justify-content-center align-items-center h-auto-i' id={'agendamento' + a.id}>
                        <AiFillEye className='pointer h-azul mx-1' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
                        {permissoes['criacao_edicao'] && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
                            <AiFillEdit className='pointer h-azul mx-1' onClick={() => modalEditar(a, horaInteira, estacao, idHorario, a.reserva, a.tipo_atendimento_id)}></AiFillEdit>
                        }
                        {permissoes['exclusão'] &&
                            <BsTrashFill className='pointer h-azul mx-1' onClick={() => excluirAgendamento(a.id)} />
                        }
                    </div>
                    <div className='col-12 h-auto-i pointer ns-abrevia-texto' onClick={(e) => { setTerapeutaSelecionado(a.terapeuta_id) }} style={a.terapeuta_status === 'Ferias' ? styles.fontFerias : {}}>
                        {a.terapeuta_nome_curto}
                    </div>
                    {!horaFinalDiferente &&
                        <div className='hora-diferente d-inline-flex' width={styles.width}>
                            Hora Final: {horaFinal}
                        </div>
                    }
                </>);
            }

            // Processamento de reserva
            const rReserva = a.reserva === 1;
            const rEspera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
            if (rReserva && rEspera) {
                reservaNoTitulo = geraDropDownAgenda(a, 'reserva', horaInteira, estacao, idHorario);
            }

            // Processamento de substituição
            const sSubstituicao = !esperaMode ? a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7 : false;
            if (sSubstituicao && aReserva) {
                substituicaoNoTitulo = geraDropDownAgenda(a, 'substituicao', horaInteira, estacao, idHorario);
            }
        };

        // Verifica agendamentos
        if (agendamento?.length > 0) {
            agendamento.forEach(processAgendamento);
        }

        let tooltipClass = '';
        function getTooltipClass() {
            if (horarioIndex === 0 || horarioIndex === 1 || horarioIndex === 2) {
                if (estacaoIndex === 0) {
                    tooltipClass = "tooltip-right-bottom";
                } else {
                    if (estacaoIndex === memorizedEstacaos.length - 1) {
                        tooltipClass = "tooltip-left-bottom";
                    } else {
                        tooltipClass = "tooltip-bottom";
                    }
                }
            } else {
                if (horarioIndex === memorizedHorarios.length - 1) {
                    if (estacaoIndex === memorizedEstacaos.length - 1) {
                        tooltipClass = "tooltip-left-top";
                    } else {
                        tooltipClass = "tooltip-right-top";
                    }
                } else {
                    tooltipClass = "tooltip-top";
                }
            }
            return tooltipClass;
        }
        getTooltipClass();

        return (
            <td className="tooltip-container tr-vazio tamanho-padrao m-0 p-0" style={{ cursor: permissoes['criacao_edicao'] ? 'pointer' : 'default' }}
                key={`linha-agendamento-${estacao.id}-${horario.id}`}
                onClick={(e) => { if (agendaContent === null) { handleOpenAgendamento(horaInteira, estacao, idHorario, e) } }}
                onMouseEnter={(e) => handleOnMouseEnter(horario.id, estacao.id)}
                onMouseLeave={(e) => handleOnMouseLeave(horario.id, estacao.id)}
            >
                <div className={'d-grid '} style={{ ...div2styleAgenda, ...styles.tamanhoPadrao }} id={`linha-agendamento-${estacao.id}-${horario.id}-div`}>
                    <div className='w-100per d-flex h-15px m-0 p-0'>
                        {reservaNoTitulo ? reservaNoTitulo : <div style={styles.agendamentoExtras}></div>}
                        <div style={styles.divTitulo}>{titulo || ' '}</div>
                        {substituicaoNoTitulo ? substituicaoNoTitulo : <div style={styles.agendamentoExtras}></div>}
                    </div>
                    {agendaContent}
                </div>
                {agendamento?.length > 0
                    ? <div className={tooltipClass}>
                        <div className={`tooltip-text`}>
                            {tooltipInfo}
                            <div className="tooltip-arrow"></div>
                        </div>
                    </div>
                    : ''
                }
            </td>
        );
    };
    /**
     * Handles the scroll event.
     *
     * @param {Event} e - The scroll event object.
     */
    const handleScroll = (e) => {
        onScrollCapture();
    };
    React.useEffect(() => {
        if (showAgendamentos === true) {
            endLoad(ref);
        }
    }, [showAgendamentos, memorizedHorarios, memorizedEstacaos]);

    return (
        <tbody
            ref={ref}
            id="tbody-grade-de-agendamentos"
            className="tbody-agenda-semanal"
            onScrollCapture={(e) => handleScroll(e)}>
            {showAgendamentos && memorizedHorarios.map((horario, index1) => {
                return (
                    <tr key={`hora-${index1}`}>
                        {memorizedEstacaos.map((estacao, index2) => {
                            return <CellCss key={`hora-${index1}|esta-${index2}`} estacao={estacao} horario={horario} horarioIndex={index1} estacaoIndex={index2} />
                        })}
                    </tr>
                )
            })}
        </tbody>
    )
})
export default TbodyAgendaSemanal;