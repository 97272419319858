import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import TerapeutaService from 'services/TerapeutaService';
const DadosBasicosModal = forwardRef(({ id = 'agendaModal' }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Dados Básicos';
    const [isOpen, setIsOpen] = useState(false);
    const [terapeuta_id, setTerapeutaId] = useState(null);
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [terapeuta, setTerapeuta] = useState([]);
    const [attributes, setAttributes] = useState({});

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: '60vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setTerapeutaId(id);
        setIsOpen(true);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaTerapeuta = (id) => {
        api.get(`/api/terapeuta/${id}/dados-basicos`).then(({ data }) => {
            setAttributes(TerapeutaService.getStatusAttributes(data.status));
            setTerapeuta(data);
        });
    }
    //#endregion
    useEffect(() => {
        if (terapeuta_id) {
            setTerapeuta([]);
            setloading(true);
            carregaTerapeuta(terapeuta_id);
            setloading(false);
        }
    }, [terapeuta_id]);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} transparent>
                <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                    <label></label>
                    <label>{title}</label>
                    <label>
                        <button className='btn-icone' onClick={closeModal}>
                            <AiOutlineClose color='red' size={22} />
                        </button>
                    </label>
                </div>
                {
                    loading
                        ? <ClipLoader className='m-auto align-center justify-center' />
                        : (terapeuta !== undefined && terapeuta !== null)
                            ?
                            <ul className='font-20px'>
                                <li className='flex-nowrap'>
                                    <b>Status:</b>
                                    <span className={attributes.className} title={attributes.title}> {terapeuta.status} </span>
                                </li>
                                <li><b>Nome:</b> {terapeuta.nome}</li>
                                <li><b>Data de nascimento:</b> {terapeuta.nascimento ? moment.utc(terapeuta.nascimento).format('DD/MM/YYYY') : '-'}</li>
                                <li><b>Data de entrada:</b> {terapeuta.data_entrada ? moment.utc(terapeuta.data_entrada).format('DD/MM/YYYY') : '-'}</li>
                                <li><b>Terapias:</b> {terapeuta.terapias}</li>
                                <li><b>Especialidades:</b> {terapeuta.especialidades}</li>
                            </ul>
                            : 'Sem terapeuta selecionado.'
                }
            </Modal>
        </>
    )
});
export default DadosBasicosModal;