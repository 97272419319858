import React, { useEffect, useMemo, useRef, useState } from "react";
import { AiFillCaretDown, AiFillFileAdd, AiOutlineClear, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import GrupoPermissoesService from "../../../../services/GrupoPermissoesService";
import TableGrupoPermissao from "./TablePermissoes";
import GrupoPermissaoModal from "../../../../components/Modais/GrupoPermissaoModal";
import AssocPermissaoModal from "../../../../components/Modais/AssocPermissaoModal";
import Admin from "../../../../layouts/Admin";
import api from "../../../../services/api";
import { BsCheck } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import { useSnackbar } from "../../../../context/snackbar";
import Swal from "sweetalert2";
import PermissaoModal from "../../../../components/Modais/PermissaoModal";
import { Form } from "@unform/web";
import { DivDisplay, FilterStyle } from "../../../../components/Filter/FiltroAtendimentos/styles";
import { DivContentDisplay } from "../../../RecepcaoDashboard/Agenda/Terapeuta/styles";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import { LinhaSpaceBetween, TituloTabelas } from "../../../../styles/global-style";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function GrupoPermissao() {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#8ed4ae");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalPermissaoIsOpen, setModalPermissaoIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalPermissaoData, setModalPermissaoData] = useState(null);
    const [modalAssocIsOpen, setModalAssocIsOpen] = useState(false);
    const [GrupoPermissaoId, setGrupoPermissaoId] = useState(null);
    const [GrupoPermissoes, setGrupoPermissoes] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const [permissoesOriginal, setPermissoesOriginal] = useState([]);

    /* VARS REF */
    const filterRef = useRef();

    /* VARS FILTRO */
    const [linkList, setLinkList] = useState();
    const [linkSelecionadoOpt, setLinkSelecionadoOpt] = useState(null);
    const [linkSelecionado, setLinkSelecionado] = useState(null);
    const [isLinkList, setIsLinkList] = useState(null);
    const [isLinkSelecionadoOpt, setLinkableSelecionadoOpt] = useState(null);
    const [linkableSelecionado, setLinkableSelecionado] = useState(null);
    const [isSubLinkList, setIsSubLinkList] = useState(null);
    const [isSubLinkSelecionadoOpt, setSublinkableSelecionadoOpt] = useState(null);
    const [sublinkableSelecionado, setSublinkableSelecionado] = useState(null);
    const [hasSubmenuList, setHasSubmenuList] = useState(null);
    const [hasSubmenuSelecionadoOpt, setHasSubmenuSelecionadoOpt] = useState(null);
    const [hasSubmenuSelecionado, setHasSubmenuSelecionado] = useState(null);
    const [expand, setExpand] = useState();

    const snackbar = useSnackbar();
    const permissoesColumns = useMemo(
        () => [
            {
                Header: 'ID: ',
                accessor: 'id',
            },
            {
                Header: 'Permissão: ',
                accessor: 'name',
            },
            {
                Header: 'Link: ',
                accessor: 'link',
            },
            {
                Header: 'Titulo: ',
                accessor: 'title',
            },
            {
                Header: 'Link?',
                accessor: 'linkable',
                Cell: ({ cell }) => (
                    (<div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }}>
                        {cell.row.original.linkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />}
                    </div>
                    )
                )
            },
            {
                Header: 'Sublink?',
                accessor: 'sublinkable',
                Cell: ({ cell }) => (
                    (<div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }}>
                        {cell.row.original.sublinkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />}
                    </div>
                    )
                )
            },
            {
                Header: 'Tem Submenu?',
                accessor: 'has_submenu',
                Cell: ({ cell }) => (
                    (<div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }}>
                        {cell.row.original.has_submenu
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />}
                    </div>
                    )
                )
            },
            {
                Header: "Ações",
                accessor: "acoes",
                Cell: ({ cell }) => (
                    (<div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }}>
                        <AiOutlineDelete style={{ cursor: 'pointer' }} onClick={() => handleDeletePermissao(cell.row.original.id)} color="#7340BD" size={22} />
                        <AiOutlineEdit style={{ cursor: 'pointer' }} onClick={() => handleEditPermissaoModal(cell.row.original)} color="#7340BD" size={22} />
                    </div>
                    )
                )
            }
        ]
    );

    const loadGrupoPermissoes = () => {
        GrupoPermissoesService.index()
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setGrupoPermissoes(data)
            })
            .finally(() => setLoading(false));
        api.get('api/permission?order_by=name:asc').then(({ data }) => {
            setPermissoes(data);
            setPermissoesOriginal(data);
            let linkList = [];
            let isLinkList = [];
            let isSubLinkList = [];
            let hasSubmenuList = [];
            for (let index = 0; index < data.length; index++) {
                const el = data[index];
                if (linkList.findIndex((e) => e.value === el.link) === -1) {
                    linkList.push({
                        label: el.link,
                        value: el.link
                    });
                }
                if (isLinkList.findIndex((e) => e.value === el.linkable) === -1) {
                    isLinkList.push({
                        label: el.linkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.linkable
                    });
                }
                if (isSubLinkList.findIndex((e) => e.value === el.sublinkable) === -1) {
                    isSubLinkList.push({
                        label: el.sublinkable
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.sublinkable
                    });
                }
                if (hasSubmenuList.findIndex((e) => e.value === el.has_submenu) === -1) {
                    hasSubmenuList.push({
                        label: el.has_submenu
                            ? <BsCheck className='success' size={30} />
                            : <CgClose className='font-vermelho' size={22} />,
                        value: el.has_submenu
                    });
                }
            }
            setLinkList(linkList);
            setIsLinkList(isLinkList);
            setIsSubLinkList(isSubLinkList);
            setHasSubmenuList(hasSubmenuList);
        }).catch((e) => {
            console.error(e);
        })
    }

    const handleEditModal = (data) => {
        setModalData(data);
        setIsOpen(true);
    }

    const handleNewGrupoDePermissao = () => {
        setModalData(null);
        setIsOpen(true);
    }
    const handleNewPermissao = () => {
        setModalPermissaoData(null);
        setModalPermissaoIsOpen(true);
    }

    const handleEditPermissaoModal = (data) => {
        setModalPermissaoIsOpen(true);
        setModalPermissaoData(data);
    }

    const handleDeletePermissao = (id) => {
        Swal.fire({
            title: 'Deseja realmente remover esta permissão ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((e) => {
            if (e.isConfirmed) {
                api.delete('api/permission/' + id).then((e) => {
                    snackbar.displayMessage('Permissão excluída com sucesso!', 'success');
                }).catch((e) => {
                    Swal.fire('Houve um problema a excluír a permissão', '', 'error');
                    console.error(e);
                })
            }
        })
    }


    //===FUN FILTRO===\
    const expandFiltro = (handleClose = false) => {
        let text = document.getElementById('blank');
        if (handleClose) {
            setExpand(false);
            text.innerHTML = "Expandir Filtro";
        } else {
            setExpand(!expand);
            if (expand === false) {
                text.innerHTML = 'Fechar Filtro';
            } else {
                text.innerHTML = "Expandir Filtro"
            }
        }
    }
    const filtraPermissoes = (value) => {
        setLinkableSelecionado(null);
        setLinkableSelecionadoOpt(null);
        setSublinkableSelecionado(null);
        setSublinkableSelecionadoOpt(null);
        setHasSubmenuSelecionado(null);
        setHasSubmenuSelecionadoOpt(null);
        let filtradosOld = permissoesOriginal;
        let filtrados = [];
        if (value) {
            filtradosOld.forEach((e) => {
                if (e.name.includes(value)) {
                    filtrados.push(e);
                }
            });
        } else {
            filtrados = filtradosOld;
        }
        setPermissoes(filtrados);
    }
    const filtrar = () => {
        let listaLocal = permissoesOriginal;
        let newListaFiltrada = listaLocal.filter((e) => {
            let link = false;
            let linkable = false;
            let sublinkable = false;
            let has_submenu = false;
            if (linkSelecionado) {
                if (String(e.link).toUpperCase().includes(String(linkSelecionado).toUpperCase())) {
                    link = true;
                }
            } else {
                link = true;
            }
            if (linkableSelecionado !== null || linkableSelecionado !== undefined) {
                if (Boolean(e.linkable) === Boolean(linkableSelecionado)) {
                    linkable = true;
                }
            } else {
                linkable = true;
            }
            if (sublinkableSelecionado !== null || sublinkableSelecionado !== undefined) {
                if (Boolean(e.sublinkable) === Boolean(sublinkableSelecionado)) {
                    sublinkable = true;
                }
            } else {
                sublinkable = true;
            }
            if (hasSubmenuSelecionado !== null || hasSubmenuSelecionado !== undefined) {
                if (Boolean(e.has_submenu) === Boolean(hasSubmenuSelecionado)) {
                    has_submenu = true;
                }
            } else {
                has_submenu = true;
            }
            return (link && linkable && sublinkable && has_submenu);
        });
        setPermissoes(newListaFiltrada);
    }


    const associarAssoc = (permissao_id) => {
        setGrupoPermissaoId(permissao_id);
        setModalAssocIsOpen(true);
    }

    useEffect(() => {
        loadGrupoPermissoes();
        // Função para verificar se o clique foi fora do componente
        function handleClickOutside(event) {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                // Feche o componente de filtro aqui
                expandFiltro(true);
            }
        }

        // Adiciona o ouvinte de evento ao documento
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Remove o ouvinte de evento ao desmontar
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        filtrar();
    }, [hasSubmenuSelecionado, linkableSelecionado, sublinkableSelecionado, linkSelecionado]);

    return (
        <Admin>
            <Container>
                <div className="accordion" id="accordion1">
                    <div className="accordion-item">
                        <LinhaSpaceBetween className="accordion-header">
                            <TituloTabelas className="mt-3 accordion-button" type='button' data-bs-toggle='collapse' data-bs-target='#listaGrupos' aria-expanded="true" aria-controls="listaGrupos" >Grupo de Permissões</TituloTabelas>
                        </LinhaSpaceBetween>
                        {(GrupoPermissoes.length !== 0 && loading === false)
                            ?
                            <div className="accordion-collapse collapse show pe-4 ps-4 pb-2" id="listaGrupos" data-bs-parent="#accordion1">
                                <Button className='w-300px' onClick={() => handleNewGrupoDePermissao()}>
                                    <AiFillFileAdd style={{ marginRight: 5 }} />
                                    Novo Grupo de Permissões
                                </Button>
                                <TableGrupoPermissao callback={loadGrupoPermissoes} source={GrupoPermissoes} openModalAssoc={associarAssoc} editModal={handleEditModal} />
                            </div>
                            : <ClipLoader color={color} loading={loading} css={override} size={50} />
                        }
                    </div>
                    <div className="accordion-item">
                        <LinhaSpaceBetween className="accordion-header">
                            <TituloTabelas className="mt-3 mb-3 accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#listaPermissoes" aria-expanded="false" aria-controls="listaPermissoes">
                                Listagem de Permissões
                            </TituloTabelas>
                        </LinhaSpaceBetween>
                        {permissoesOriginal.length !== 0
                            ?
                            <Container className="accordion-collapse collapse pe-4 ps-4 pb-2" id="listaPermissoes" data-bs-parent="#accordion1"> {/* FILTRO */}
                                <Form style={DivDisplay}>
                                    <div ref={filterRef} style={FilterStyle}>
                                        <div style={{ width: "100%", marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div className="w-100per pointer" onClick={() => expandFiltro()}>
                                                <div style={{ display: "flex" }}>
                                                    <p id="blank" style={{ marginBottom: -30 }}>Expandir Filtro</p>
                                                </div>
                                                <div style={{ cursor: "pointer", marginLeft: "96%" }}>
                                                    <AiFillCaretDown color="white" size={22} />
                                                </div>
                                            </div>
                                            {expand &&
                                                <div className="div-expand-over" style={{ marginLeft: '-15px' }}>
                                                    <div style={DivContentDisplay} className="row">
                                                        <div className={"col-12"}>
                                                            <label className="label-filtro">Link:</label>
                                                            <div className="d-flex justify-around align-center">
                                                                <Select name={'identificador'} options={linkList} value={linkSelecionadoOpt} onChange={(e) => {
                                                                    setLinkSelecionado(e.value);
                                                                    setLinkSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                                <AiOutlineClear className="pointer m-2" onClick={(e) => { setLinkSelecionado(null); setLinkSelecionadoOpt(null); }} size={25} />
                                                            </div>
                                                        </div>
                                                        <div className={"col-12"}>
                                                            <label className="label-filtro">Link?:</label>
                                                            <div className="d-flex justify-around align-center">
                                                                <Select name={'paciente'} options={isLinkList} value={isLinkSelecionadoOpt} onChange={(e) => {
                                                                    setLinkableSelecionado(e.value);
                                                                    setLinkableSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                                <AiOutlineClear className="pointer m-2" onClick={(e) => { setLinkableSelecionado(null); setLinkableSelecionadoOpt(null); }} size={25} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={DivContentDisplay} className="row">
                                                        <div className={"col-12"}>
                                                            <label className="label-filtro">Sublink?:</label>
                                                            <div className="d-flex justify-around align-center">
                                                                <Select name="status" options={isSubLinkList} value={isSubLinkSelecionadoOpt} onChange={(e) => {
                                                                    setSublinkableSelecionado(e.value);
                                                                    setSublinkableSelecionadoOpt({ value: e.value, label: e.label })
                                                                }} />
                                                                <AiOutlineClear className="pointer m-2" onClick={(e) => { setSublinkableSelecionado(null); setSublinkableSelecionadoOpt(null); }} size={25} />
                                                            </div>
                                                        </div>
                                                        <div className={"col-12"}>
                                                            <label className="label-filtro">Tem Submenu:</label>
                                                            <div className="d-flex justify-around align-center">
                                                                <Select name="status" options={hasSubmenuList} value={hasSubmenuSelecionadoOpt} onChange={(e) => {
                                                                    setHasSubmenuSelecionado(e.value);
                                                                    setHasSubmenuSelecionadoOpt({ value: e.value, label: e.label });
                                                                }} />
                                                                <AiOutlineClear className="pointer m-2" onClick={(e) => { setHasSubmenuSelecionado(null); setHasSubmenuSelecionadoOpt(null); }} size={25} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="label-filtro" htmlFor={'filtroPermissoes'}>Nome da Permissão:</label>
                                                            <div className="d-flex justify-around align-center pr-25px">
                                                                <input id="filtroPermissoes" name="filtroPermissoes" className="form-control" onChange={(e) => filtraPermissoes(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <Button className='w-20per' type='button' onClick={() => handleNewPermissao()}>
                                        <AiFillFileAdd style={{ marginRight: 5 }} />
                                        Nova Permissão
                                    </Button>
                                </Form>
                                <TableDefaultPagination className='mt-20px' source={permissoes} columns={permissoesColumns} ></TableDefaultPagination>{/* TABELA */}
                            </Container>
                            : <ClipLoader color={color} loading={loading} css={override} size={50} />
                        }
                    </div>
                </div>
                {modalIsOpen && <GrupoPermissaoModal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    callback={loadGrupoPermissoes}
                    modalData={modalData}
                />}
                {modalPermissaoIsOpen && <PermissaoModal
                    modalData={modalPermissaoData}
                    modalIsOpen={modalPermissaoIsOpen}
                    setIsOpen={setModalPermissaoIsOpen}
                    callback={loadGrupoPermissoes}>
                </PermissaoModal>
                }
                {modalAssocIsOpen && <AssocPermissaoModal
                    modalIsOpen={modalAssocIsOpen}
                    setIsOpen={setModalAssocIsOpen}
                    groupId={GrupoPermissaoId}
                />}
            </Container>
        </Admin>)
}