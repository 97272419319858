//#region IMPORTAÇÕES
import React, { forwardRef, useEffect, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Input from '../../../../../components/Input';
import InputMask from "../../../../../components/InputMask";
import { Form } from '@unform/web';
import Cep from '../../../../../services/cep';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useSnackbar } from '../../../../../context/snackbar';
import ResponsavelService from '../../../../../services/ResponsavelService';
import { CPFService } from '../../../../../services/CPFService';
import moment from 'moment';
import api from '../../../../../services/api';
import { CgCheck, CgClose } from 'react-icons/cg';
import Button from '../../../../../components/Button';
import { ClipLoader } from 'react-spinners';
//#endregion

//#region STYLES
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        height: '70vh',
        width: '50%',
        overflowX: 'hidden',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};
const errorStyle = {
    borderColor: 'rgb(255, 47, 47)',
    color: 'rgb(128, 0, 0)',
    margin: 0,
}
const sucessStyle = {
    borderColor: 'greenyellow',
    color: 'green',
    margin: 0,
}
//#endregion

const ModalResponsavel = (props, ref) => {
    //#region VARIÁVEIS
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dados, setDados] = React.useState(null);
    const [isResponsavelFiscal, setIsResponsavelFiscal] = useState(false);

    const [loading, setloading] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmailUnico, setIsEmailUnico] = useState(false);
    const [email, setEmail] = useState();
    //===VARS VALIDADORES==============
    const [isCPFValid, setIsCPFValid] = useState(false);
    const [customStyleCPF, setCustomStyleCPF] = useState([]);
    //#endregion

    //#region FUNÇÕES / MÉTODOS
    const validaEmail = async (email, user_id) => {
        let isValid = email ? /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(email) : false;
        let isEmailUnico = await validaEmailUnico(email, user_id);
        setIsEmailUnico(isEmailUnico);
        setIsEmailValid(isValid && isEmailUnico);
        if (!isValid) {
            Swal.fire('Email inválido!', "Por favor, verifique o e-mail inserido. Pode haver acentos ou faltar um '@'.", 'error');
        }
    }
    const validaEmailUnico = async (email, user_id = null) => {
        if (props.modalData !== null && props.modalData !== undefined) user_id = props.modalData.user_id ? props.modalData.user_id : props.modalData.id;
        let dadosResponsavel = null;
        let dadosUsuario = null;
        let verifyType = 'responsavel';
        let pacienteId = props?.paciente_id;
        let testObj = { email: email, id: user_id, verify_type: verifyType, paciente_id: pacienteId };
        let isValid = await api.post('api/valida/email/unico', testObj)
            .then(({ data }) => {
                dadosResponsavel = data.responsavel;
                dadosUsuario = data.usuario;
                return data.isValid;
            })
            .catch((e) => console.error(e));
        if (!isValid) {
            if (dadosResponsavel) {
                let html = `
                <div>
                    <p>
                        Por favor, verifique o email: ${dadosResponsavel?.email}.
                        </br>
                        O usuário cadastrado tem esses dados:
                    </p>
                    <ul style="text-align: left;">
                        <li><b>Nome: </b>${dadosResponsavel?.nome}</li>
                        <li><b>Data Nascimento: </b>${dadosResponsavel?.data_nascimento ? moment.utc(dadosResponsavel.data_nascimento).format('DD/MM/YYYY') : 'Sem data informada.'}</li>
                        <li><b>CPF: </b>${dadosResponsavel?.cpf}</li>
                    </ul>
                    <div class="w-100 text-left">
                        <p><b>Dados necessário para vinculo:</b></p>
                    </div>
                    <div class="text-left">
                        <label class="form-label" for="parentesco_swal">Parentesco:</label>
                        <input class="form-control" type="text" id="parentesco_swal" name="parentesco_swal" required/>
                    </div>
                    <div class="text-left mt-2">
                        <label class="form-label">Responsabilidades:</label>
                        <div class="text-left">
                            <input type="checkbox" class="btn-check" autoComplete="off" id="responsavel_fiscal_swal" name="responsavel_fiscal_swal">
                            <label class="btn btn-outline-primary" for="responsavel_fiscal_swal">Responsável Legal</label>
                        </div>
                    </div>
                </div>`;

                Swal.fire({
                    title: 'Email já cadastrado no sistema!',
                    html: html,
                    icon: 'warning',
                    showConfirmButton: true,
                    confirmButtonText: 'Vincular Responsável ao Paciente',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        let parentesco = document.getElementById('parentesco_swal').value;
                        let responsavel_fiscal = document.getElementById('responsavel_fiscal_swal').checked;
                        dadosResponsavel = {
                            ...dadosResponsavel,
                            user_id: null,
                            responsavel_id: dadosResponsavel.id,
                            parentesco: parentesco,
                            responsavel_fiscal: responsavel_fiscal,
                            responsavel_legal: responsavel_fiscal,
                            ja_registrado: true
                        }
                        handleSubmit(dadosResponsavel);
                    }
                });
            } else {
                if (dadosUsuario) {
                    let html = `
                    <div>
                        <p>
                            Por favor, verifique o email: ${dadosUsuario?.email}.
                            </br>
                            O usuário cadastrado tem esses dados:
                        </p>
                        <ul style="text-align: left;">
                            <li><b>Nome: </b>${dadosUsuario?.name}</li>
                            <li><b>Data Nascimento: </b>${dadosUsuario?.data_nascimento ? moment.utc(dadosUsuario.data_nascimento).format('DD/MM/YYYY') : 'Sem data informada.'}</li>
                            <li><b>CPF: </b>${dadosUsuario?.cpf}</li>
                        </ul>
                        <div class="w-100 text-left">
                            <p><b>Dados necessário para vinculo:</b></p>
                        </div>
                        <div class="text-left">
                            <label class="form-label" for="parentesco_swal">Parentesco:</label>
                            <input class="form-control" type="text" id="parentesco_swal" name="parentesco_swal" required/>
                        </div>
                        <div class="text-left mt-2">
                            <label class="form-label">Responsabilidades:</label>
                            <div class="text-left">
                                <input type="checkbox" class="btn-check" autoComplete="off" id="responsavel_fiscal_swal" name="responsavel_fiscal_swal">
                                <label class="btn btn-outline-primary" for="responsavel_fiscal_swal">Responsável Legal</label>
                            </div>
                        </div>
                    </div>`;

                    Swal.fire({
                        title: 'Email já cadastrado no sistema!',
                        html: html,
                        icon: 'warning',
                        showConfirmButton: true,
                        confirmButtonText: 'Vincular Responsável ao Paciente',
                        showCancelButton: true,
                        cancelButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let parentesco = document.getElementById('parentesco_swal').value;
                            let responsavel_fiscal = document.getElementById('responsavel_fiscal_swal').checked;
                            dadosUsuario = {
                                ...dadosUsuario,
                                user_id: dadosUsuario.id,
                                responsavel_id: null,
                                parentesco: parentesco,
                                responsavel_fiscal: responsavel_fiscal,
                                responsavel_legal: responsavel_fiscal,
                                ja_registrado: true
                            }
                            handleSubmit(dadosUsuario);
                        }
                    });
                } else {
                    Swal.fire('Email já cadastrado no sistema!', '', 'warning');
                }
            }
        }
        return isValid;
    }
    const validaCPF = (value = null) => {
        let response = CPFService.ValidaCPF(value);
        setIsCPFValid(response.isValid);
        setCustomStyleCPF(response.style);
    }
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco_resp').value = data.logradouro
        document.getElementById('bairro_resp').value = data.bairro
        document.getElementById('estado_resp').value = data.uf
        document.getElementById('cidade_resp').value = data.localidade
    }

    const handleChangeFiscal = (e) => {
        if (e.target.checked) {
            setIsResponsavelFiscal(true);
        } else {
            setIsResponsavelFiscal(false);
        };
    }

    const checkResponsavelFiscal = (data) => {
        if (data === true) return true
        else return false
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        if (props.modalData !== null && props.modalData !== undefined) {
            setIsResponsavelFiscal(checkResponsavelFiscal(props.modalData.responsavel_fiscal));
        }
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const editResponsavelNewPaciente = (responsavel, dados) => {
        let responsavelLocal = [...props.responsaveis];
        const index = responsavelLocal.indexOf(responsavel);
        responsavelLocal.splice(index, 1, dados);
        props.setResponsaveis(responsavelLocal);
    }

    async function handleSubmit(data) {
        try {
            if (data.nome === "" || data.cpf === "" || data.data_nascimento === "" || data.rg === "" || data.email === "" || data.cep === "" || data.telefone1 === "" || data.parentesco === "") {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                /* Quando não existe paciente ainda */
                let isCadastroPaciente = props.paciente_id === null || props.paciente_id === undefined;
                /* Quando é criação do responsavel */
                let isCriacaoResponsavel = props.modalData === null || props.modalData === undefined;
                /* Situação em que sera criado um novo responsável */
                let responsavelNaoRegistrado = data.ja_registrado === undefined || data?.ja_registrado === false || data?.ja_registrado === null;
                /* Lista de responsáveis */
                let responsaveisLocal = [...props.responsaveis];

                if (responsavelNaoRegistrado || !isCriacaoResponsavel) {
                    data.data_nascimento = data?.data_nascimento.split('/').reverse().join('-');
                    data.telefone1 = data?.telefone1?.replace(/[\D]/g, '');
                    data.telefone2 = data?.telefone2?.replace(/[\D]/g, '');
                    data.responsavel_fiscal = isResponsavelFiscal;
                    data.cpf = data?.cpf?.replace(/[\D]/g, '');
                    data.cep = data?.cep?.replace(/[\D]/g, '');
                }

                if ((isCPFValid && isEmailValid) || (!responsavelNaoRegistrado && isCriacaoResponsavel)) {
                    // Se for um novo paciente e um novo responsável está sendo criado
                    if (isCadastroPaciente && isCriacaoResponsavel) {
                        responsaveisLocal.push({ ...data, ja_registrado: !responsavelNaoRegistrado });
                        props.setResponsaveis(responsaveisLocal);
                        snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                        setIsOpen(false);
                        return true;
                    }

                    // Se for um novo paciente e um responsável existente está sendo editado
                    if (isCadastroPaciente && !isCriacaoResponsavel) {
                        editResponsavelNewPaciente(props.modalData, { ...data, ja_registrado: !responsavelNaoRegistrado });
                        snackbar.displayMessage("Cadastro alterado com sucesso!", "success");
                        setIsOpen(false);
                        return true;
                    }

                    // Se for um paciente existente e um novo responsável está sendo criado
                    if (!isCadastroPaciente && responsavelNaoRegistrado && isCriacaoResponsavel) {
                        await ResponsavelService.store({ ...data, id_paciente: props.paciente_id }).then((response) => {
                            const newData = response.data;
                            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                            responsaveisLocal.push({ ...newData, ja_registrado: false });
                            props.setResponsaveis(responsaveisLocal);
                        }).catch((e) => {
                            console.error(e);
                            snackbar.displayMessage("Houve um erro ao fazer o cadastro!", "error");
                        }).finally(() => {
                            setIsOpen(false);
                            return true;
                        });
                    }

                    // Se for um paciente existente e um responsável existente está sendo editado
                    if (!isCadastroPaciente && !isCriacaoResponsavel) {
                        if (!responsavelNaoRegistrado) {
                            api.put(`api/paciente-responsavel/${props.modalData.pr_id}?id=${props.modalData.pr_id}`, {
                                parentesco: data.parentesco,
                                responsavel_legal: data.responsavel_fiscal,
                                responsavel_fiscal: data.responsavel_fiscal
                            }).catch((e) => {
                                console.error(e);
                                snackbar.displayMessage("Houve um erro ao fazer o cadastro!", "error");
                            });
                            delete (data.parentesco);
                            delete (data.responsavel_fiscal);
                            delete (data.responsavel_legal);
                        }
                        ResponsavelService.update({ ...data, user_id: props.modalData.user_id, id: props.modalData.id }, props.modalData.id).then(() => {
                            snackbar.displayMessage("Cadastro alterado com sucesso!", "success");
                            let responsavelLocal = [...props.responsaveis];
                            for (let index = 0; index < responsavelLocal.length; index++) {
                                const e = responsavelLocal[index];
                                if (e.id === data.id) {
                                    responsavelLocal[index] = { ...data, ja_registrado: !responsavelNaoRegistrado };
                                    break;
                                }
                            }
                            props.setResponsaveis(responsavelLocal);
                        }).catch((e) => {
                            console.error(e);
                            snackbar.displayMessage("Houve um erro ao fazer o cadastro!", "error");
                        }).finally(() => {
                            setIsOpen(false);
                            return true;
                        });
                    }

                    // Se for um paciente existente e um responsável existente está sendo atribuído ao paciente
                    if (!isCadastroPaciente && !responsavelNaoRegistrado && isCriacaoResponsavel) {
                        let newData = {
                            responsavel_id: data.responsavel_id,
                            user_id: data.user_id,
                            paciente_id: props.paciente_id,
                            parentesco: data.parentesco,
                            responsavel_legal: data.responsavel_fiscal,
                            responsavel_fiscal: data.responsavel_fiscal
                        }
                        api.post('api/paciente-responsavel', newData).then(() => {
                            responsaveisLocal.push({ ...data, ...newData, pr_id: data.id, nome: data.name === undefined ? data.nome : data.name, ja_registrado: !responsavelNaoRegistrado });
                            props.setResponsaveis(responsaveisLocal);
                            snackbar.displayMessage("Cadastro alterado com sucesso!", "success");
                        }).catch((e) => {
                            console.error(e);
                            snackbar.displayMessage("Houve um erro ao fazer o cadastro!", "error");
                        }).finally(() => {
                            setIsOpen(false);
                            return true;
                        });
                    }
                } else {
                    Swal.fire('Dado Inválido', `O ${!isCPFValid ? 'CPF' : ''} ${!isCPFValid && !isEmailValid ? ' e ' : ''} ${!isEmailValid ? ' email' : ''} cadastrado esta inválido. Por favor veririque o valor inserido, e tente novamente.`, 'error');
                }
            }
        } catch (error) {
            Swal.fire('Houve um erro ao fazer o cadastro!', '', 'error');
            console.error(error);
        }
    }

    const functionThatSubmitForm = () => {
        formRef.current.submitForm();
    }
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        if (props.modalData !== undefined && props.modalData !== null) {
            validaCPF(props.modalData.cpf);
            validaEmail(props.modalData.email, props.modalData.id);
        }
    }, [props]);
    //#endregion

    //#region HTML
    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Cadastro de Responsável do Paciente." transparent appElement={document.getElementById('root')} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div style={{ position: "relative" }}>
                <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", right: "10px", fontSize: "25px", top: "-10px" }} onClick={() => setIsOpen(false)}>
                    x
                </label>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 style={{ color: "#442953", fontWeight: "bold", textAlign: 'center' }} ref={(_subtitle) => (subtitle = _subtitle)}>
                    {props.modalData === null || props.modalData === undefined ? "Cadastrar Responsável do Paciente" : "Alterar Responsável do Paciente"}
                </h2>
                <Form ref={formRef} onSubmit={handleSubmit} className='row pt-4' style={{ borderWidth: 1, borderTopColor: "red" }} >
                    <div className='col-lg-4 col-sm-12'>
                        <Input title="Nome:" name="nome" required={'required'} defaultValue={props?.modalData?.nome} />
                    </div>
                    <div className='col-lg-2 col-sm-12'>
                        <Input title="Parentesco:" name="parentesco" required={'required'} defaultValue={props?.modalData?.parentesco} />
                    </div>
                    <div className='col-lg-3 col-sm-12'>
                        <InputMask containerStyle={customStyleCPF} onChange={(e) => validaCPF(e.target.value)} defaultValue={props.modalData === null || props.modalData === undefined ? '' : props.modalData.cpf} title="CPF:" name="cpf" icon={null} maskFormat={"999.999.999-99"} disabled={false} required={'required'} className="w-100" />
                    </div>
                    <div className='col-lg-3 col-sm-12'>
                        <Input
                            type={'date'}
                            title="Data de Nascimento:"
                            name="data_nascimento"
                            defaultValue={
                                (props.modalData !== null && props.modalData !== undefined && props.modalData.data_nascimento !== null && props.modalData.data_nascimento !== undefined)
                                    ? moment.utc(props.modalData.data_nascimento).format('YYYY-MM-DD')
                                    : ''
                            }
                        />
                    </div>
                    <div className='col-lg-4 col-sm-12'>
                        <Input title="RG:" name="rg" required={'required'} defaultValue={props?.modalData?.rg} />
                    </div>
                    <div className='col-lg-4 col-sm-12'>
                        <InputMask title="Telefone 01:" name="telefone1" icon={null} maskFormat={"(99)99999-9999"} disabled={false} required={'required'} defaultValue={props?.modalData?.telefone1} className="w-100" />
                    </div>
                    <div className='col-lg-4 col-sm-12'>
                        <InputMask title="Telefone 02:" name="telefone2" icon={null} maskFormat={"(99)99999-9999"} disabled={false} defaultValue={props?.modalData?.telefone2} className="w-100" />
                    </div>
                    <div className='col-lg-9 col-sm-12'>
                        <Input type={'email'} title="Email:" name="email" required={'required'} defaultValue={props?.modalData?.email} onChange={(e) => setEmail(e.target.value)}
                            containerStyle={isEmailValid ? sucessStyle : errorStyle}
                            sideContent={
                                <button
                                    className={`btn btn-${isEmailValid ? 'success' : 'danger'} d-flex text-center justify-content-center align-items-center flex-wrap w-fit-content-i`}
                                    type='button'
                                    onClick={() => validaEmail(email, props?.modalData?.id)}
                                    width={40} height={40}>
                                    {isEmailValid
                                        ? <CgCheck style={{ color: 'white', width: 'fit-content', margin: '0px' }} size={22} />
                                        : <CgClose style={{ color: 'white', width: 'fit-content', margin: '0px' }} size={22} />
                                    }
                                </button>
                            } />
                    </div>
                    <div className='col-lg-3 col-sm-12'>
                        <InputMask title="CEP:" name="cep" icon={null} maskFormat={"99999-999"} disabled={false} required={'required'} onBlur={(e) => getCep(e.target.value)} defaultValue={props?.modalData?.cep} className="w-100" />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Input title="Rua:" name="endereco" id="endereco_resp" defaultValue={props?.modalData?.endereco} />
                    </div>
                    <div className='col-lg-2 col-sm-12'>
                        <Input title="Número:" name="numero" required={'required'} defaultValue={props?.modalData?.numero} />
                    </div>
                    <div className='col-lg-2 col-sm-12'>
                        <Input title="Bairro:" name="bairro" required={'required'} id="bairro_resp" defaultValue={props?.modalData?.bairro} />
                    </div>
                    <div className='col-lg-2 col-sm-12'>
                        <Input title="Cidade:" name="cidade" required={'required'} id="cidade_resp" defaultValue={props?.modalData?.cidade} />
                    </div>
                    <div className='col-lg-2 col-sm-12'>
                        <Input title="Estado:" name="estado" required={'required'} id="estado_resp" defaultValue={props?.modalData?.estado} />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <Input title="Complemento:" name="complemento" defaultValue={props?.modalData?.complemento} />
                    </div>
                    <div className='col-12 mt-3'>
                        <input id="responsavel_fiscal" name="responsavel_fiscal" required={'required'} type={'checkbox'} defaultChecked={isResponsavelFiscal} checked={isResponsavelFiscal} className='me-2' onChange={handleChangeFiscal} />
                        <label htmlFor='responsavel_fiscal' className='unselectable'>Responsável Legal</label>
                    </div>
                    <div className='col-12'>
                        <div className='w-100'>
                            <Input title="Senha:" type={'text'} name="password" required={props.modalData === null || props.modalData === undefined ? 'required' : undefined} defaultValue={props?.modalData?.password} />
                        </div>
                    </div>
                    <div className='col-12'>
                        <Button className='btn-padrao' type="button" onClick={functionThatSubmitForm} disabled={loading || !isEmailValid}>
                            {!loading
                                ? props.modalData === null || props.modalData === undefined
                                    ? "Adicionar"
                                    : "Alterar"
                                : <ClipLoader color={"#fff"} size={25} />
                            }
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
    //#endregion
}

export default forwardRef(ModalResponsavel);
