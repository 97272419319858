import React from "react";
import { BotaoAcaoStyle, InputStyle, MainDivStyle, OptionsStyle, TextFormat } from "./styles";
import { RiFileExcel2Fill } from "react-icons/ri";

const TablePagination = ({
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    pageIndex,
    pageCount,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageSize,
    linhas,
    exportToExcel
}) => {
    return (
        <div style={MainDivStyle}>
            <div style={{}}>
                <button className='btn-outline' onClick={exportToExcel} style={{ marginBottom: '10px' }}>
                    <RiFileExcel2Fill className='font-roxo' size={26} />
                </button>
                <button style={BotaoAcaoStyle} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {"<<"}
                </button>{" "}
                <button style={BotaoAcaoStyle} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {"<"}
                </button>{" "}
                <button style={BotaoAcaoStyle} onClick={() => nextPage()} disabled={!canNextPage}>
                    {">"}
                </button>{" "}
                <button style={BotaoAcaoStyle} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {">>"}
                </button>{" "}
            </div>
            <div>
                <span style={TextFormat}>
                    Página:{" "}
                    <strong style={TextFormat}>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{" "}
                </span>
                <span style={TextFormat}> | Linhas: {linhas} | </span>
                <span style={TextFormat}>
                    Ir para página:{" "}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={InputStyle} />
                </span>{" "}
                <select style={OptionsStyle}
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[4, 8, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}
export default TablePagination;