import { Container } from "styles";
import { ContainerProntuarios } from "./styles";
import ProntuarioCard from "pages/RecepcaoDashboard/Pacientes/Prontuarios/ProntuarioCard";
import { useState } from "react";
import { BotaoAcaoStyle } from "components/TablePagination/styles";

function TableProntuarios({ paciente }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = paciente.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(paciente.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <div className="w-100">
            <ContainerProntuarios>
                {currentItems.length
                    ? currentItems.map(prontuario => {
                        return (
                            <ProntuarioCard key={prontuario.id} paciente={prontuario} />
                        )
                    })
                    : <div className="text-center">
                        Não foram encontrados pacientes.
                    </div>
                }
            </ContainerProntuarios>
            <div className="d-flex justify-content-center align-items-center">
                <button className="me-4" style={BotaoAcaoStyle} onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span>{currentPage} de {totalPages}</span>
                <button className="ms-4" style={BotaoAcaoStyle} onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Próximo
                </button>
            </div>
        </div >
    );
}

export default TableProntuarios;