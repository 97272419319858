import React from "react";
import { ClipLoader } from "react-spinners";
import './styles.css';

const Carregamento = ({ cor = 'purple', loading = null }) => {
    return (
        <div id="carregamento" className={'carregando'} style={(loading === null || loading === true) ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <div>
                <ClipLoader color={cor} size={100}></ClipLoader>
            </div>
        </div>
    );
};

export default Carregamento;