import React, { useMemo } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { TableDefault } from '../../../../../components/TableDefault';
import ResponsavelService from "../../../../../services/ResponsavelService";
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import { EmergenciasPacienteService } from "../../../../../services/EmergenciasPacienteService";
import api from "../../../../../services/api";

export function TableEmergenciasPaciente({ source, setModalEmergenciasData, pacienteId, setEmergencias, callBack, handleOpen, readOnly = false }) {

    const snackbar = useSnackbar();

    const removeEmergencia = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    let emergenciasLocal = [...source];
                    if (data.paciente_id === null || data.paciente_id === undefined) {
                        const index = emergenciasLocal.indexOf(data);
                        emergenciasLocal.splice(index, 1);
                        setEmergencias(emergenciasLocal);
                        snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                        callBack()
                    } else {
                        api.delete(`api/paciente-contato-emergencia/${data.id}?id=${data.id}`).then(() => {
                            snackbar.displayMessage("Cadastro removido com sucesso!", "success")

                            const index = emergenciasLocal.indexOf(data);
                            emergenciasLocal.splice(index, 1);
                            setEmergencias(emergenciasLocal);
                        })
                    }
                } catch {
                    Swal.fire('Houve um problema ao excluir o cadastro', '', 'warning')
                };
            }
        });
    }

    const handleEditEmergencia = (data) => {
        setModalEmergenciasData(data);
        handleOpen();
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => readOnly ? [
            {
                Header: 'Contato',
                accessor: 'nome',
            },
            {
                Header: 'Telefone',
                accessor: 'telefone',
            }
        ] : [
            {
                Header: 'Contato',
                accessor: 'nome',
            },
            {
                Header: 'Telefone',
                accessor: 'telefone',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        return (
                            <div className="d-flex align-items-center justify-content-center bg-transparente m-2">
                                <AiOutlineEdit className="pointer" color="#7340BD" size={22} onClick={e => handleEditEmergencia(cell.row.original)} />
                                <AiOutlineDelete className="pointer" color="#7340BD" size={22} onClick={e => removeEmergencia(cell.row.original)} />
                            </div>
                        )
                    } else {
                        return <></>
                    }
                }
            }
        ],
        [source]
    )

    return (
        <TableDefault columns={columns} source={data} />
    );
}
