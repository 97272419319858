import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Public from '../../layouts/Public';
import Button from "./components/Button";
import Input from "./components/Input";
import { ScaleLoader } from "react-spinners";
import { FiLock, FiUser } from "react-icons/fi";
import { Form } from "@unform/web";
import { Container } from "./styles";
import RecuperarSenhaModal from './RecuperarSenha';
import { useAuth } from "../../context/auth";
import { useSnackbar } from '../../context/snackbar';
import Swal from 'sweetalert2';
import api from '../../services/api';
import { ChangeCase } from 'services/ChangeCase';

const override = `
    display: block;
    margin-top: 8px;
    margin-left: 4px;
    border-color: orange;
`;

const Login = () => {
	//#region VARIAVEIS
	const formRef = useRef(null);
	const modalRef = useRef(null);
	const navigate = useNavigate();
	const { displayMessage } = useSnackbar();
	const { signIn, changeTipoUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [dualBoot, setDualBoot] = useState();
	const [multiAcessos, setMultiAcessos] = useState([]);
	const [usuario, setUsuario] = useState([]);
	//#endregion
	//#region FUNCOES
	/**
	 * Função que exibe um modal para alteração de senha.
	 * @param {Object} user Objeto com todos os dados do usuário.
	 * @param {String} mensagemErro String com a mensagem de erro a ser exibida.
	 * @param {String} senha Senha a ser preenchida no campo de senha.
	 * @param {String} confirma Confirmação da senha a ser preenchida no campo de confirmação.
	 * @returns Objeto com status e mensagem de retorno.
	 */
	const alteraSenha = async (user, mensagemErro = null, senha = '', confirma = '') => {
		try {
			const someClass = mensagemErro ? 'some' : '';
			const result = await Swal.fire({
				title: mensagemErro ? mensagemErro : 'Trocar senha inicial!',
				text: !mensagemErro ? 'A senha de acesso que você usou, é a senha inicial. Por favor, troque-a para continuar.' : '',
				html: `
				<h4 class="${someClass}">Quer trocar agora?</h4>
					<div class="w-100 d-flex flex-col">
							<div class="w-100 text-left">
								<b class="${someClass}">Certifique-se de que é você:</b>
								<p><b>Nome:</b> ${user.name}</p>
								<p><b>Email:</b> ${user.email}</p>
							</div>
							<div class="w-100 form-group text-left">
									<label class="form-label">Nova Senha:</label>
									<input type="password" id="swal-senha" class="form-control" value="${senha}" placeholder="Digite sua nova senha"
										onKeyUp="
											const password = document.getElementById('swal-senha');
											const confPassword = document.getElementById('swal-confirma');
											const logErro = document.getElementById('log-erro');
											if(confPassword.value) {
												if(password.value !== confPassword.value) {
													logErro.innerText = 'As senhas devem ser iguais!';
													logErro.style.color = 'red';
													password.style.borderColor = 'red';
													confPassword.style.borderColor = 'red';
												}else{
													logErro.innerText = '';
													password.style.borderColor = 'green';
													confPassword.style.borderColor = 'green';
												}
											}else{
												logErro.innerText = 'Digite uma confirmação!';
												logErro.style.color = 'red';
												password.style.borderColor = '';
												confPassword.style.borderColor = 'red';
											}
										" required>
							</div>
							<div class="w-100 form-group text-left">
									<label class="form-label">Confirme a Senha:</label>
									<input type="password" id="swal-confirma" class="form-control" value="${confirma}" placeholder="Confirme sua nova senha"
										onKeyUp="
											const password = document.getElementById('swal-senha');
											const confPassword = document.getElementById('swal-confirma');
											const logErro = document.getElementById('log-erro');
											if(confPassword.value) {
												if(password.value !== confPassword.value) {
													logErro.innerText = 'As senhas devem ser iguais!';
													logErro.style.color = 'red';
													password.style.borderColor = 'red';
													confPassword.style.borderColor = 'red';
												}else{
													if(password.value.length < 6) {
														logErro.innerText = 'A senha deve ter no mínimo 6 caracteres!';
														logErro.style.color = 'red';
														password.style.borderColor = 'red';
														confPassword.style.borderColor = 'red';
													}else{
														logErro.innerText = '';
														password.style.borderColor = 'green';
														confPassword.style.borderColor = 'green';
													}
												}
											}else{
												logErro.innerText = 'Digite uma confirmação!';
												logErro.style.color = 'red';
												password.style.borderColor = '';
												confPassword.style.borderColor = 'red';
											}
										" required>
							</div>
							<p id="log-erro"></p>
					</div>
				`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Cadastrar Nova Senha',
				cancelButtonText: 'cancelar',
			});
			if (result.isConfirmed) {
				const senha = document.getElementById('swal-senha').value;
				const confirma = document.getElementById('swal-confirma').value;
				if (senha) {
					if (confirma) {
						if (senha !== confirma) {
							await alteraSenha(user, 'As senhas não são iguais, tente novamente.', senha, confirma);
							return;
						} else {
							if (senha.length < 6) {
								await alteraSenha(user, 'A senha deve ter no mínimo 6 caracteres.', senha, confirma);
								return;
							}
						}
					} else {
						await alteraSenha(user, 'Confirme a senha para continuar.', senha, confirma);
						return;
					}
				} else {
					await alteraSenha(user, 'Digite uma senha para continuar.', senha, confirma);
					return;
				}
				const response = await api.post('api/trocar-senha', { id: user.id, password: senha });
				Swal.fire('Senha alterada!', 'Sua senha foi alterada com sucesso, faça login novamente.', 'success');
				return { status: 200, message: 'Senha alterada com sucesso!' };
			}
		} catch (err) {
			console.error(err);
			Swal.fire('Ops!', 'Não foi possível alterar sua senha, tente novamente.', 'error');
			return { status: 500, message: 'Não foi possível alterar sua senha.' };
		}
	}
	//#endregion
	//#region HANDLES
	const handleOpenModal = useCallback(() => {
		modalRef.current.openModal();
	}, []);
	const handleSubmit = async (data) => {
		try {
			setLoading(true);
			const response = await signIn({ username: data.Login, password: data.Senha });
			const usuario = response?.data?.user;
			const multiAcessos = usuario?.paciente_responsavels;

			if (response.status === 401 || response.data.message === "Invalid Credentials") {
				displayMessage("Usuário ou senha inválidos", "error");
				return;
			}
			if (response.data.message === "Senha inicial, por favor troque-a.") {
				const user = response.data.user;
				await alteraSenha(user);
				return '';
			}
			if (response.data.message === "Acesso expirou") {
				Swal.fire("Acesso expirado!", "Parece que seu acesso expirou, converse com a coordenação para receber a liberação, e a nova senha.", "error");
				return;
			}
			if (usuario?.documentos_pendentes?.length > 0) {
				navigate(`/documentos-pendentes`);
				return;
			}
			if (usuario) {
				if (usuario.status?.toLowerCase() === 'inativo') {
					Swal.fire("Usuário inativo!", "Usuário inativo, entre em contato com a coordenação para ativar seu acesso.", "error");
					return;
				}
				let navUrl = '/dashboard';
				/* if (multiAcessos) {
						const dualBoot = multiAcessos ? true : false;
						setDualBoot(dualBoot);
						setMultiAcessos(multiAcessos);
						setUsuario(usuario);
						return;
				} */
				switch (usuario.tipo_user.toLowerCase()) {
					case 'terapeuta':
						const terapeutaStatus = usuario.terapeuta[0]?.status;
						const terapeutaRoutes = {
							'Bloqueado': '/terapeuta/acesso-bloqueado',
							'Ativo': '/terapeuta/',
							'Liberado': '/terapeuta/'
						};
						navigate(navUrl + (terapeutaRoutes[terapeutaStatus] || '/terapeuta/'));
						break;
					default:
						navigate(navUrl + `/${usuario.tipo_user.toLowerCase()}/`);
						break;
				}
			}
		} catch (err) {
			console.error(err);
			displayMessage(err.message + '. Não foi possível acessar!', 'error');
		} finally {
			if (!formRef.current) return;
			setLoading(false);
		}
	};
	const handleResponsavelClick = () => {
		changeTipoUser('RESPONSAVEL');
	}
	//#endregion
	useEffect(() => {
		sessionStorage.setItem('teste', 'teste');
		localStorage.setItem('teste', 'teste');
	}, []);
	//#region HTML
	return (
		<Public>
			<Container>

				{dualBoot
					? <div>
						{multiAcessos &&
							<div className='d-flex justify-content-center'>
								<Button type="button" className="p-2 mx-2 h-auto" onClick={(e) => { handleResponsavelClick() }}>
									Acessar como Responsável
								</Button>
								<Button type="button" className="p-2 mx-2 h-auto" onClick={(e) => navigate(`/dashboard/${usuario?.tipo_user.toLowerCase()}`)}>
									Acessar como {ChangeCase.toCapitalizedCase(usuario?.tipo_user)}
								</Button>
							</div>
						}
					</div>
					: <>
						<Form ref={formRef} onSubmit={handleSubmit}>
							<div className='d-flex justify-content-center'>
								<img width={400} src={'logo.png'} alt="Logo" />
							</div>
							<div className="form-group font-branca">
								<label className="form-label">Login:</label>
								<Input
									name="Login"
									icon={FiUser}
									placeholder="Digite seu Login ou Email"
									type="text"
									required
									autoComplete="username"
								/>
							</div>
							<div className="form-group font-branca">
								<label className="form-label">Senha:</label>
								<Input name="Senha" icon={FiLock} placeholder="Digite sua Senha" type="password" required autoComplete="current-password" />
							</div>

							<div className='d-flex justify-content-center'>
								<label onClick={handleOpenModal} className="ns-forgot-password">Esqueceu sua senha?</label>
							</div>
							{loading
								? <ScaleLoader css={override} size={150} color={"#fd931a"} loading={loading} />
								: <div className='d-flex justify-content-center'>
									<Button type="submit">Entrar</Button>
								</div>
							}
						</Form>
						<RecuperarSenhaModal ref={modalRef} />
					</>
				}
			</Container>
		</Public>
	);
	//#endregion
}

export default Login;
