import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Estilo para o Overlay
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

// Estilo para o destaque
const Highlight = styled.div`
    position: absolute;
    border: 4px solid #4caf50;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease;
    pointer-events: none;
    animation: pulse 1.5s infinite;
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.9);
        }
        70% {
            box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
        }
    }
`;

// Estilo para o conteúdo do tutorial
const Content = styled.div`
  position: absolute;
  background: white;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1002;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: #71FF77;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ButtonFinalizar = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #B14C4C;
  color: white;
  border-radius: 5px;

  &:hover {
    background-color: #FF7171;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const bgActive = '#4caf50';
const bgActiveHover = '#71FF77';
const bgInactive = '#3F7041';
const bgInactiveHover = '#5FA061';

const Badge = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #4caf50;
  color: white;
  padding: 0px;
  cursor: pointer;
  z-index: 1002;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #71FF77;
    color: yellow;
  }
`;

const ListagemTopicos = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50;
    color: white;
    padding: 10px;
    z-index: 1002;
    border-radius: 10px;
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    font-size: 16px;
    font-weight: bold;

    h3 {
        margin: 0;
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    p {
        margin: 5px 0;
        font-size: 14px;
    }

    div {
        cursor: pointer;
        padding: 5px;
        border-bottom: 1px solid #71FF77;

        &:hover {
            background-color: #71FF77;
            color: yellow;
        }
    }
`;

const Tutorial = ({ steps, onFinish, hasTopicos }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [highlightPosition, setHighlightPosition] = useState({});
    const [windowWidth, setWindowWidth] = useState(null);
    const [windowHeight, setWindowHeight] = useState(null);
    const [currentTop, setCurrentTop] = useState('50%');
    const [currentLeft, setCurrentLeft] = useState('50%');
    const contentRef = useRef(null);

    useEffect(() => {
        const previousStyles = new Map();

        if (steps[currentStep]) {
            const selectors = Array.isArray(steps[currentStep].selector) ? steps[currentStep].selector : [steps[currentStep].selector];

            const mainElement = selectors.map(selector => document.querySelector(selector)).find(el => el !== null);

            const sharedStyles = {
                /*
                position: 'relative',
                zIndex: 1001,
                */
            };

            selectors.forEach(selector => {
                const element = document.querySelector(selector);
                if (element) {
                    previousStyles.set(element, {
                        position: element.style.position,
                        zIndex: element.style.zIndex,
                    });
                    Object.assign(element.style, sharedStyles);
                }
            });

            if (mainElement) {
                const rect = mainElement.getBoundingClientRect();
                if (Array.isArray(steps[currentStep].selector)) {
                    setHighlightPosition(selectors.map(selector => {
                        const element = document.querySelector(selector);
                        if (element) {
                            const rect = element.getBoundingClientRect();
                            return {
                                top: rect.top + window.scrollY - 5,
                                left: rect.left + window.scrollX - 5,
                                width: rect.width + 10,
                                height: rect.height + 10,
                            };
                        }
                    }));
                } else {
                    setHighlightPosition({
                        top: rect.top + window.scrollY - 5,
                        left: rect.left + window.scrollX - 5,
                        width: rect.width + 10,
                        height: rect.height + 10,
                    });
                }
            }
        }

        return () => {
            if (steps[currentStep]) {
                const selectors = Array.isArray(steps[currentStep].selector) ? steps[currentStep].selector : [steps[currentStep].selector];
                selectors.forEach(selector => {
                    const element = document.querySelector(selector);
                    if (element && previousStyles.has(element)) {
                        const { position, zIndex } = previousStyles.get(element);
                        element.style.position = position;
                        element.style.zIndex = zIndex;
                    }
                });
            }
        };
    }, [currentStep, steps]);

    useEffect(() => {
        if (windowWidth && windowHeight) {
            criaPosicionamento();
        }
    }, [highlightPosition, windowWidth, windowHeight, currentStep]);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            setWindowWidth(screenWidth);
            setWindowHeight(screenHeight);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleFinish = () => {
        onFinish();
        setCurrentStep(0);
    };

    const criaPosicionamento = () => {
        const x = !steps[currentStep].selector
            ? windowHeight * 0.5
            : highlightPosition.top + highlightPosition.height + 8 + contentRef.current.offsetHeight > windowHeight
                ? windowHeight - highlightPosition.height - contentRef.current.offsetHeight - 30
                : highlightPosition.top + highlightPosition.height + 8;
        const y = !steps[currentStep].selector
            ? windowWidth * 0.5
            : highlightPosition.left + highlightPosition.width + (steps[currentStep]?.width || 350) > windowWidth
                ? windowWidth - (steps[currentStep]?.width || 350)
                : highlightPosition.left;
        setCurrentTop(x);
        setCurrentLeft(y);
    };

    return (
        <Overlay>
            {steps[currentStep].selector ? (
                typeof steps[currentStep].selector === 'string' ? (
                    <Highlight
                        style={{
                            top: `${highlightPosition.top}px`,
                            left: `${highlightPosition.left}px`,
                            width: `${highlightPosition.width}px`,
                            height: `${highlightPosition.height}px`,
                        }}
                    />
                ) : (
                    steps[currentStep].selector.map((selector, index) => {
                        console.log('teste');
                        return (
                            <Highlight
                                key={index}
                                style={{
                                    top: `${highlightPosition[index]?.top}px`,
                                    left: `${highlightPosition[index]?.left}px`,
                                    width: `${highlightPosition[index]?.width}px`,
                                    height: `${highlightPosition[index]?.height}px`,
                                }}
                            />
                        )
                    })
                )
            ) : null}

            <Content
                ref={contentRef}
                style={{
                    width: steps[currentStep]?.width || 350,
                    height: steps[currentStep]?.height || 'auto',
                    transition: 'all .5s ease-in-out',
                    position: 'absolute',
                    top: `${currentTop}px`,
                    left: `${currentLeft}px`,
                    ...!steps[currentStep].selector && { transform: 'translate(-50%, -50%)' },
                }}
            >
                <button
                    style={{
                        position: 'absolute',
                        top: '3px',
                        right: '10px',
                        background: 'transparent',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer',
                        color: 'red',
                    }}
                    onClick={handleFinish}
                >
                    &times;
                </button>
                <p className='m-auto mt-0'>{steps.length > 1 ? `Passo ${currentStep + 1} de ${steps.length}` : ''}</p>
                <p className='mt-2'>{steps[currentStep].text}</p>

                <Controls>
                    <Button className='unselectable' onClick={handleBack} disabled={currentStep === 0}>
                        Voltar
                    </Button>

                    {currentStep === steps.length - 1 ? (
                        <ButtonFinalizar className='unselectable' onClick={handleFinish}>
                            Finalizar
                        </ButtonFinalizar>
                    ) : (
                        <Button className='unselectable' onClick={handleNext}>
                            Próximo
                        </Button>
                    )}
                </Controls>
            </Content>
            {hasTopicos &&
                <Topicos steps={steps} toggleSelect={setCurrentStep} selectedIndex={currentStep} />}
        </Overlay>
    );
};

const TutorialBadge = ({ steps, startsOn, hasTopicos }) => {
    const [showTutorial, setShowTutorial] = useState(startsOn !== undefined ? true : false);

    const handleFinish = () => {
        setShowTutorial(prev => !prev);
    };

    return (
        <>
            <Badge
                className='unselectable'
                onClick={() => setShowTutorial((prev) => !prev)}
                style={{
                    animation: showTutorial ? 'pulse 1.5s infinite' : 'none',
                    backgroundColor: showTutorial ? bgActive : bgInactive,
                    hover: {
                        backgroundColor: showTutorial ? bgActiveHover : bgInactiveHover,
                    },
                }}>?</Badge>
            {showTutorial && <Tutorial steps={steps} onFinish={handleFinish} hasTopicos={hasTopicos} />}
            <style>
                {`
                @keyframes pulse {
                    0% {
                    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.9);
                    }
                    70% {
                    box-shadow: 0 0 0 20px rgba(76, 175, 80, 0);
                    }
                    100% {
                    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
                    }
                }
                `}
            </style>
        </>
    );
};

const Topicos = ({ steps, selectedIndex = 0, toggleSelect = () => { } }) => {
    const [expandedIndex, setExpandedIndex] = useState(selectedIndex);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    useEffect(() => {
        setExpandedIndex(selectedIndex);
    }, [selectedIndex]);

    return (
        <ListagemTopicos>
            <h4 className='mx-auto text-center font-bold'>Tópicos ({selectedIndex + 1}/{steps.length})</h4>
            {steps.map((step, index) => (
                <div key={index}
                    style={{
                        backgroundColor: selectedIndex === index ? '#71FF77' : 'transparent',
                        color: selectedIndex === index ? 'black' : 'white',
                    }}
                >
                    <h3>
                        <span className='h-100per' onClick={() => toggleSelect(index)}>
                            <span className='me-2'>
                                {selectedIndex === index && '▶'}
                            </span>
                            <span>
                                {index + 1}. {step.title}
                            </span>
                        </span>
                        <span className='h-100per' style={{ marginLeft: '10px' }} onClick={() => toggleExpand(index)}>
                            {expandedIndex === index ? '▲' : '▼'}
                        </span>
                    </h3>
                    {expandedIndex === index && <p>{step.text}</p>}
                </div>
            ))}
        </ListagemTopicos>
    );
};

export { Tutorial, TutorialBadge };